<template>
    <div class="container">
        <el-checkbox-group v-model="selectedSymptom" class="symptom-list">
            <el-checkbox
                class="symptom"
                v-for="symptom in symptoms"
                :label="symptom.id"
                :key="symptom.id"
            >
                {{ symptom.title }}
            </el-checkbox>
        </el-checkbox-group>
        <el-input placeholder="其他" v-model="symptomOther"></el-input>
        <div class="reservation-btn-group">
            <button @click="previous">返回</button>
            <button @click="validate">下一步</button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { sysSymptoms } from "@/api";
export default {
    data: function () {
        return {
            selectedSymptom: [],
            symptomOther: "",
            symptoms: [],
        };
    },
    computed: {
        ...mapState({
            selectedClinic: (state) => state.reservation.selectedClinic,
            hasSymptom: (state) => state.reservation.hasSymptom,
            symptomData: (state) => state.reservation.symptomData,
        }),
    },
    methods: {
        ...mapMutations({
            next: "reservation/next",
            previous: "reservation/previous",
            setSymptomData: "reservation/setSymptomData",
        }),
        validate() {
            if (this.selectedSymptom.length == 0 && this.symptomOther === "") {
                this.$message({
                    type: "error",
                    message: "請選擇至少一個症狀",
                });
                return false;
            }
            this.setSymptomData({
                selectedSymptom: this.selectedSymptom,
                symptomOther: this.symptomOther,
            });
            this.next();
        },
    },
    mounted: function () {
        let data = {
            clinic_id: this.selectedClinic.id,
            pageSize: 999,
        };
        sysSymptoms(data).then((res) => {
            this.symptoms = res.data.dataList;
        });
        if (this.hasSymptom) {
            this.selectedSymptom = this.symptomData.selectedSymptom;
            this.symptomOther = this.symptomData.symptomOther;
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 16px 16px 60px 16px;
    position: relative;
    .symptom-list {
        margin-bottom: 16px;
    }
    .symptom ::v-deep {
        width: 100%;
        border-bottom: 1px solid $black;
        padding: 8px;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 0;
        .el-checkbox__label {
            padding-left: 0;
        }
    }
}
</style>