<template>
    <div class="container">
        <div class="time-list">
            <el-radio
                class="time"
                v-for="time in timeList"
                :label="time.id"
                :key="time.id"
                v-model="selectedTimeId"
                >{{ time.title }}</el-radio
            >
        </div>
        <div class="reservation-btn-group">
            <button @click="previous">返回</button>
            <button @click="validate">下一步</button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { sysDiseaseTimes } from "@/api";
export default {
    data: function () {
        return {
            selectedTimeId: "",
            timeList: [],
        };
    },
    methods: {
        ...mapMutations({
            next: "reservation/next",
            previous: "reservation/previous",
            setDiseaseTime: "reservation/setDiseaseTime",
        }),
        validate() {
            if (this.selectedTimeId != "") {
                this.setDiseaseTime(this.selectedTimeId);
                this.next();
            } else {
                this.$message({
                    type: "error",
                    message: "請選擇發病時間",
                });
            }
        },
    },
    computed: {
        ...mapState({
            selectedClinic: (state) => state.reservation.selectedClinic,
            diseaseTimeId: (state) => state.reservation.diseaseTimeId,
        }),
    },
    mounted: function () {
        let data = {
            pageSize: 999,
            clinic_id: this.selectedClinic.id,
        };
        sysDiseaseTimes(data).then((res) => {
            this.timeList = res.data.dataList;
        });
        this.selectedTimeId = this.diseaseTimeId;
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 16px 16px 60px 16px;
    position: relative;
    .time-list {
        margin-bottom: 16px;
    }
    .time ::v-deep {
        width: 100%;
        border-bottom: 1px solid $black;
        padding: 8px;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 0;
        .el-radio__label {
            padding-left: 0;
        }
    }
}
</style>