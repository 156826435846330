<template>
    <section class="doctor-info">
        <img :src="doctorData.avatar" alt="" />
        <div class="content">
            <div class="title">
                <span class="name">{{ doctorData.name }}</span>
                <span class="gender">{{ genderInText }}</span>
            </div>
            <div class="department">{{ doctorData.specialist }}</div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        genderInText: function () {
            if (this.doctorData.gender == 1) return "男";
            else return "女";
        },
    },
    props: {
        doctorData: {
            type: Object,
            required: false,
            default: () => {
                return {
                    gender: "",
                    name: "",
                    specialist: "",
                    avatar: "",
                };
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.doctor-info {
    background-color: $green-300;
    color: white;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
    img {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: white;
    }
    .content {
        @include flexc-end-stretch;
        .title {
            @include flexr-start-baseline;
            .name {
                font-size: 24px;
            }
            .gender {
                margin-left: 16px;
                font-size: 16px;
            }
            .department {
                font-size: 16px;
            }
        }
    }
}
</style>