<template>
  <div class="common-questions-container">
    <!-- <div class="button-container">
            <button @click="openCommonQuestions">
                <i class="icon-question-mark"></i>
                <span>常見問題</span>
            </button>
            <button @click="closeBrowser">
                <i class="icon-baseline-close-px"></i>
            </button>
        </div> -->
    <div class="set-row">
      <div class="top-right">
        <i @click="openCommonQuestions" class="icon-question-mark"></i>
        <span @click="openCommonQuestions" class="top-right-span"
          >常見問題</span
        >
        <i @click="closeBrowser" class="icon-baseline-close-px"></i>
      </div>
    </div>

    <div class="common-questions-page" :class="{ active: showCommonQuestions }">
      <div class="header" @click="setShowCommonQuestions(false)">
        <i class="icon-leftarrow" @click="main_dispay"> </i>
        <span @click="main_dispay">常見問題</span>
      </div>
      <div class="question-list">
        <div
          class="question"
          v-for="(question, index) in questionList"
          :key="question.id"
          @click="openQuestionDetail(index)"
        >
          <span>{{ question.question }}</span>
          <i class="icon-rightarrow"></i>
        </div>
      </div>
    </div>
    <div class="question-detail-page" :class="{ active: showQuestionDetail }">
      <div
        class="header"
        @click="setShowQuestionDetail(false)"
        :style="{ 'margin-top': statusBarHeight }"
      >
        <i class="icon-leftarrow"> </i>
        返回
      </div>
      <img src="@/assets/img/common-question-detail-bg.png" alt="" />
      <div class="question-detail">
        <div class="question">
          <div class="title">問題</div>
          <span class="content">{{ selectedQuestion.question }}</span>
        </div>
        <div class="answer">
          <div class="title">答案</div>
          <span class="content">{{ selectedQuestion.answer }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sysFaqsList } from "@/api";
import { isSafari } from "@/assets/js";
import { mapMutations, mapState } from "vuex";
export default {
  data: function () {
    return {
      statusBarHeight: "0vh",
    };
  },
  created() {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = "2vh";
    }
  },
  computed: {
    ...mapState({
      showCommonQuestions: (state) => state.commonQuestions.showCommonQuestions,
      showQuestionDetail: (state) => state.commonQuestions.showQuestionDetail,
      questionList: (state) => state.commonQuestions.questionList,
      selectedQuestion: (state) => state.commonQuestions.selectedQuestion,
    }),
  },
  methods: {
    main_dispay() {
      this.$store.state.commonQuestions_display = 0;
    },
    ...mapMutations({
      setShowCommonQuestions: "commonQuestions/setShowCommonQuestions",
      setShowQuestionDetail: "commonQuestions/setShowQuestionDetail",
      setQuestionList: "commonQuestions/setQuestionList",
      setSelectedQuestion: "commonQuestions/setSelectedQuestion",
    }),
    openCommonQuestions() {
      this.$store.state.commonQuestions_display = 1;
      this.setShowCommonQuestions(true);
    },
    openQuestionDetail(index) {
      this.setSelectedQuestion(this.questionList[index]);
      this.setShowQuestionDetail(true);
    },

    closeBrowser() {
      let isSa = isSafari();

      this.$confirm("離開返回主頁! (所有資料需要重新填寫)", "", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      })
        .then(() => {
        try {
          window.uni.postMessage({
              data: {
                action: "exit_web",
              },
          });
          if (window.webBackToAndroid) window.webBackToAndroid.onBack();
          else if (window.webkit) {
          window.webkit.messageHandlers.getDataFromWeb.postMessage("close");
          } else if (isSa) {
          console.log("is sa ===========");
          // iOS端关闭网页方法
          window.location.href = "iMeddy://com.imeddy.client?close=1";
          } else {
          this.$router.go(-1);
          }
         } catch (e) { 
          this.$router.go(-1);
        }
      
        })
        .catch(() => {});
    },
  },
  mounted: function () {
    window.addEventListener("message", this.handleMessage);
    sysFaqsList({ pageSize: 999 }).then((res) => {
      let questionList = [];
      res.data.dataList.forEach((e) => {
        questionList.push({
          id: e.id,
          question: e.title,
          answer: e.answer,
        });
      });
      this.setQuestionList(questionList);
    });
  },
};
</script>

<style lang="scss" scoped>
.set-row {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0%;
  width: auto;
  height: 25px;
  position: relative;
}
.top-right {
  background-color: white;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0%;
  width: 110px;
  height: 25px;
  position: absolute;
  border: 1px solid #d0d0d0;
  border-radius: 15px;
  padding: 0 8px;
  font-size: 12px;
}
.top-right-span {
  color: $black;
  padding: 0 6px;
  border-right: 1px solid #d0d0d0;
  margin-right: 4px;
}
.button-container {
  @include flexr-end-center;
  button {
    font-size: 12px;
    height: 25px;
    color: $black;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    padding: 0 8px;
    span {
      margin-right: 5px;
      padding: 0 8px;
      border-right: 1px solid #d0d0d0;
    }
  }
  button:first-of-type {
    border-right: none;
    border-radius: 15px 0 0 15px;
    padding-right: 0;
  }
  button:last-of-type {
    border-left: none;
    border-radius: 0 15px 15px 0;
    padding-left: 0;
  }
}
.common-questions-page {
  @include open-from-right;
  z-index: 700;
  background-image: url("../assets/img/common-questions-bg.png");
  background-size: cover;
  padding: calc(var(--safe-top) + 16px) 16px var(--safe-bottom);
  @include flexc-start-stretch;
  overflow: hidden;
  .header {
    padding: 16px;
    font-size: 24px;
    font-weight: $semi-bold;
    color: #fff;
  }
  .question-list {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    @include flexc-start-stretch;
    .question {
      flex-shrink: 0;
      margin-bottom: 16px;
      background-color: #fff;
      color: $black;
      padding: 12px;
      font-size: 16px;
      font-weight: $regular;
      @include flexr-center-center;
      span {
        flex-grow: 1;
        margin-right: 8px;
      }
      i {
        flex-shrink: 0;
      }
    }
  }
}
.question-detail-page {
  @include open-from-right;
  padding: 0 0 var(--safe-bottom);
  z-index: 800;
  background-color: white;
  .header {
    position: absolute;
    top: var(--safe-top);
    left: 0;
    padding: 16px;
    color: white;
    font-size: 24px;
    font-weight: $semi-bold;
  }
  .question-detail {
    color: $black;
    padding: 16px;
    & > div {
      margin-bottom: 16px;
    }
    .title {
      font-size: 18px;
    }
    .content {
      font-size: 16px;
    }
  }
}
.active {
  transform: scaleX(1);
}
</style>