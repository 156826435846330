<template>
  <el-checkbox-group
    @change="$emit('change', agreementList.includes(2))"
    v-model="agreementList"
    class="agreement"
  >
    <el-checkbox :label="1">儲存信用卡資料作將來診所預約之用</el-checkbox>
    <el-checkbox :label="2"
      >我已閱讀並在此同意就本人的視像診症而選 擇的醫療提供者之<span
        @click.stop="openRules"
        >私穩政策</span
      >和<span @click.stop="openPrivacy">條款及細則</span>
    </el-checkbox>
    <el-checkbox :label="3"
      >本人反對，就本人的視像診症而選擇的醫療提供者，按照其所迹之私穩政策使用本人得個人資料作直接促銷用途</el-checkbox
    >
  </el-checkbox-group>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data: function () {
    return {
      agreementList: this.hasChecked ? [1, 2, 3] : [],
    };
  },
  props: {
    hasChecked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      rules: (state) => state.sysConfig.termsConditionsContent,
      privacy: (state) => state.sysConfig.privacyStatementContent,
    }),
  },
  methods: {
    ...mapMutations({
      next_clause: "reservation/next_clause",
      next_privacy: "reservation/next_privacy",
    }),
    //條款及細則
    openRules() {
      this.next_clause();
    },
    //私穩政策
    openPrivacy() {
      this.next_privacy();
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement {
  background-color: white;
  padding: 16px;
  border-bottom: 1px solid $black;
  span {
    color: $green-200;
  }
}
</style>