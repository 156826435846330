<template>
  <div style="display: none" :style="{ display: 'block' }" class="container">
    <!-- <img src="@/assets/img/payment-methods.png" alt="" /> -->
    <div class="merchant-info">
      <p class="name" style="width: 100%; height: 1vh">訂單號&nbsp;:</p>
      <p class="value">{{ reservationSuccessData.orderNumber }}</p>
      <p style="width: 100%; height: 1vh" class="name">交易金額&nbsp;：</p>
      <p class="value">$ {{ reservationSuccessData.amount }}</p>
      <p></p>
    </div>
    <!-- <p style="font-weight: 600;padding:16px;">支付方式&nbsp;:</p> -->
    <div class="payment-methods-list">
      <div class="payment-methods-title">支付方式&nbsp;:</div>
      <div class="no-data" v-if="paymentMethods.length == 0">
        暫無可用支付方式
      </div>
      <label
        class="payment-method"
        v-for="method in paymentMethods"
        :key="method.id"
      >
        <div class="radio-wrapper" @click="getPaymentType(method.type)">
          <el-radio
            v-model="selectedPaymentMethod"
            :label="method.id"
          ></el-radio>
          <img
            class="payment-icon"
            :src="method.image"
            alt=""
            style="margin-left: 5vw"
          />
          <span class="method-name" style="margin-left: 5vw">{{
            method.title
          }}</span>
        </div>
        <p></p>
      </label>
    </div>
    <div class="reservation-btn-group">
      <button @click="previous">返回</button>
      <button @click="validate">付款</button>
    </div>
    <!-- 选择微信支付 弹框 支付提示 -->
    <el-dialog
      title="掃碼支付"
      :visible.sync="dialogVisible"
      width="80%"
      class="dialogPay"
      :close-on-click-modal="false"
    >
      <label
        >請保存截圖，並自行前往微信支付<br />
        (支付成功後會收到短訊通知預約成功)</label
      >
      <img style="width: 200px; height: 200px" :src="getWXSrcUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { OrdersPayment, createOrders } from "@/api";
import { paymentTypes } from "@/api";
// import otherPaymentMethods from "../../../components/otherPaymentMethods.vue";
export default {
  // components: { otherPaymentMethods },
  data: function () {
    return {
      selectedPayment: {
        type: -1,
        id: -1,
      },
      paymentMethods: [],
      selectedPaymentMethod: "",
      selectedPaymentType: 0,
      getWXSrcUrl: "",
      dialogVisible: false,
      intervalId: null,
      getPaymentData: null,
      getPaymentTypeId: 0,
      loading: null,
    };
  },
  methods: {
    ...mapMutations({
      previous: "reservation/previous",
    }),
    getPaymentType(type) {
      this.selectedPaymentType = type;
    },
    next() {
      this.$router.push({
        name: "reservationComplete",
        params: { id: this.reservationSuccessData.id },
      });
    },
    //設置定時器 進行檢測是否已經支付
    setTimeOutWxPay() {
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        if (!this.dialogVisible) {
          clearInterval(this.intervalId);
          this.intervalId = null; //设置为null
        } else {
          this.getOrderIsPay();
        }
      }, 3000);
    },

    //進行查看訂單是否已經支付
    getOrderIsPay() {
      let data = {
        id: this.reservationSuccessData.orderNumber,
        type_id: this.getPaymentTypeId,
        patient_case_id: this.getPaymentData.patient_case_id,
      };
      createOrders(data)
        .then((res) => {
          console.log(res.data);
          if (res.data.status == 1) {
            this.$message({
              type: "success",
              message: "支付成功",
            });
            clearInterval(this.intervalId);
            this.intervalId = null; //设置为null
            this.$router.replace({ name: "index" });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
          clearInterval(this.intervalId);
          this.intervalId = null; //设置为null
          console.error(err);
          if (err.code == 201) {
            this.$router.replace({ name: "index" });
          }
        });
    },

    validate() {
      if (!this.selectedPaymentMethod) {
        this.$message({
          type: "error",
          message: "請選擇支付方式",
        });
        return false;
      }
      let data = {
        order_number: this.reservationSuccessData.orderNumber,
        amount: this.reservationSuccessData.amount,
        payment_type: this.selectedPaymentMethod,
      };

      this.loading = this.$loading({
        lock: true,
        text: "Loading....",
        spinner: "el-icon-loading",
        // background: "rgba(0, 0, 0, 0.7)",
      });
      OrdersPayment(data)
        .then((res) => {
          this.loading.close();
          this.getPaymentData = res.data;
          this.getPaymentTypeId = res.data.type_id;
          if (this.selectedPaymentType != 4) {
            window.location.href = res.data.bank_transaction_url;
          } else {
            this.dialogVisible = true;

            this.getWXSrcUrl = res.data.bank_transaction_url;
            this.setTimeOutWxPay();
            // console.log(res.data.bank_transaction_url);
          }

          // 目前先全部使用打开新网页的方式，之后如果有不同操作再分情况分类
          // if (this.selectedPayment.type === 1) {
          //     window.location = res.data.bank_transaction_url;
          // } else if (this.selectedPayment.type === 3) {
          //     window.location = res.data.bank_transaction_url;
          // } else if (this.selectedPayment.type === 5) {
          //     window.location = res.data.bank_transaction_url;
          // }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState({
      reservationSuccessData: (state) =>
        state.reservation.reservationSuccessData,
    }),
  },
  created: function () {
    let data = {
      clinic_id: this.reservationSuccessData.clinicId,
      type: 2,
      status: 1,
    };
    paymentTypes(data).then((res) => {
      this.paymentMethods = res.data.dataList;
      // this.selectedPaymentMethod = this.paymentMethods[0]
      //   ? this.paymentMethods[0].id
      //   : "";
      // this.getPaymentTypeId = this.paymentMethods[0].id;
    });
  },
};
</script>

<style lang="scss" scoped>
.dialogPay {
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.payment-methods-list {
  padding: 3.5vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 10px;
  .payment-methods-title {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
  }
  .no-data {
    text-align: center;
    font-size: 16px;
    margin: 10px 0;
    color: #999999;
  }
  .payment-method {
    p {
      background: #ccc;
      height: 0.5px;
      margin-top: 0.5vh;
    }
    // margin: 10px 0;
    height: 10vh;
    // display: flex;
    justify-content: space-between;
    align-items: center;
    .el-radio {
      margin-bottom: 0;
    }
    .radio-wrapper ::v-deep {
      .el-radio__label {
        display: none;
      }
      .el-radio__inner {
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #1e3456;
        background-color: transparent;
        margin-top: 3px;
      }
      .el-radio__inner::after {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border: 0;
        box-sizing: border-box;
        background: #1e3456;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0, 0);
      }
      .is-checked {
        .el-radio__inner::after {
          transform: translate(-50%, -50%) scale(1, 1);
        }
      }
    }
    .radio-wrapper {
      // padding: 0 10px;
      display: flex;
      justify-content: left;
      align-items: center;
      .method-name {
        margin-right: 10px;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .payment-icon {
    width: 10vw;
    height: 6vh;
    // border: 2px solid #1e3456;
    // border-radius: 50%;
    box-sizing: border-box;
    object-fit: contain;
  }
}
.container {
  @include flexc-start-stretch;
  position: relative;
  padding-bottom: 60px;
  img {
    width: 50%;
    margin: 16px 0;
    align-self: center;
  }
  .merchant-info {
    padding: 16px;
    color: #fff;
    // column-gap: 16px;
    background-color: $green-300;
    // display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    font-size: 21px;
    .name {
      font-weight: $semi-bold;
    }
  }
}
</style>