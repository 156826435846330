import { render, staticRenderFns } from "./10_paymentMethod.vue?vue&type=template&id=20150fc4&scoped=true&"
import script from "./10_paymentMethod.vue?vue&type=script&lang=js&"
export * from "./10_paymentMethod.vue?vue&type=script&lang=js&"
import style0 from "./10_paymentMethod.vue?vue&type=style&index=0&id=20150fc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20150fc4",
  null
  
)

export default component.exports