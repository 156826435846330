<template>
    <div class="container">
        <img src="@/assets/img/deliver.png" alt="" />
        <section>
            <address-input v-model="localAddressData"></address-input>
        </section>
        <div class="reservation-btn-group">
            <button @click="previous">返回</button>
            <button @click="validate">下一步</button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import addressInput from "../../../components/addressInput.vue";
export default {
    components: { addressInput },
    data: function () {
        return {
            localAddressData: {
                address1: "",
                addressRegion: "",
                addressArea: "",
                addressProvince: "",
            },
        };
    },
    computed: {
        ...mapState({
            hasAddressData: (state) => state.reservation.hasAddressData,
            addressData: (state) => state.reservation.addressData,
            userData: (state) => state.reservation.userData,
        }),
    },
    methods: {
        ...mapMutations({
            next: "reservation/next",
            previous: "reservation/previous",
            setAddressData: "reservation/setAddressData",
            confirmHasAddressData: "reservation/confirmHasAddressData",
        }),
        validate() {
            for (let key in this.localAddressData) {
                if (!this.localAddressData[key]) {
                    this.$message({
                        type: "error",
                        message: "請完整輸入地址信息",
                    });
                    return false;
                }
            }
            this.setAddressData(this.localAddressData);
            this.confirmHasAddressData();
            this.next();
        },
    },
    mounted: function () {
        if (this.hasAddressData) {
            for (let key in this.localAddressData) {
                this.localAddressData[key] = this.addressData[key];
            }
        } else {
            for (let key in this.localAddressData) {
                if (this.userData[key] != undefined)
                    this.localAddressData[key] = this.userData[key];
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    position: relative;
    padding: 16px 16px 60px 16px;
    img {
        width: 100%;
        margin-bottom: 16px;
    }
}
</style>