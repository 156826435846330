<template>
    <div class="history-patients-container" :class="{ active }">
        <header @click="back"><i class="icon-leftarrow"></i>返回</header>
        <div class="patient-list">
            <div
                v-for="patient in patientRecords"
                :key="patient.id"
                class="patient"
                @click="selectPatient(patient)"
            >
                <span>{{ patient.name }}</span>
                <i class="icon-rightarrow"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { getPatientRecords } from "@/api";
import { mapMutations } from "vuex";
export default {
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    data: function () {
        return {
            patientRecords: [],
        };
    },
    methods: {
        ...mapMutations({
            setAddressData: "reservation/setAddressData",
            setPatientData: "reservation/setPatientData",
            confirmHasAddressData: "reservation/confirmHasAddressData",
            confirmHasPatientData: "reservation/confirmHasPatientData",
        }),
        selectPatient(patient) {
            this.setPatientData(patient);
            this.setAddressData(patient);
            this.confirmHasAddressData();
            this.confirmHasPatientData();
            this.$emit("update");
        },
        back() {
            this.$emit("back");
        },
    },
    mounted: function () {
        getPatientRecords().then((res) => {
            this.patientRecords = res.data.dataList;
        });
    },
};
</script>

<style lang="scss" scoped>
.history-patients-container {
    @include open-from-right;
    @include flexc-center-stretch;
    background-color: $white;
    padding-top: 16px;
    z-index: 600;
    header {
        font-size: 24px;
        color: $green-300;
        padding: 16px;
        font-weight: $semi-bold;
    }
    .patient-list {
        flex-grow: 1;
        overflow: auto;
        .patient {
            @include flexr-between-center;
            padding: 16px;
            background-color: $green-300;
            color: $white;
            font-weight: $semi-bold;
            margin-bottom: 16px;
        }
    }
}
.active {
    transform: scaleX(1);
}
</style>