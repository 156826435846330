<template>
    <div class="container">
        <section>
            <h1>
                收貨地址
                <span v-if="!editing" @click="startEdit">編輯</span>
            </h1>
            <div v-if="!editing">
                <div class="content">{{ addressProvince }}</div>
                <div class="content">{{ addressArea }}</div>
                <div class="content">{{ addressRegion }}</div>
                <div class="content">{{ address1 }}</div>
            </div>
            <address-input v-model="form.addressData" v-else></address-input>
        </section>
        <section>
            <h1>收貨人資料</h1>
            <div v-if="!editing" class="content">{{ contact }}</div>
            <el-input v-else v-model="form.contact"></el-input>
            <div v-if="!editing" class="content">
                {{ phone }}
            </div>
            <el-input v-else v-model="form.phone"></el-input>
            <el-checkbox v-if="!editing" v-model="checkAgreement"
                >點擊確認，即表示你授權將你的藥物於上述送貨地址交付給你（或任何具有你檔案號碼的人）
            </el-checkbox>
        </section>
        <div v-if="!editing" class="reservation-btn-group">
            <button @click="previous">返回</button>
            <button @click="validate">前往付款</button>
        </div>
        <div v-else class="reservation-btn-group">
            <button @click="back">返回</button>
            <button @click="save">儲存</button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import addressInput from "../../../components/addressInput.vue";
export default {
    components: { addressInput },
    data: function () {
        return {
            form: {
                addressData: {
                    address1: "1",
                    addressRegion: "",
                    addressArea: "",
                    addressProvince: "",
                },
                contact: "",
                phone: "",
            },
            address1: "",
            addressProvince: "",
            addressRegion: "",
            addressArea: "",
            contact: "",
            phone: "",

            editing: false,
            checkAgreement: false,
        };
    },
    methods: {
        ...mapMutations({
            next: "reservation/next",
            previous: "reservation/previous",
            setConsigneeData: "reservation/setConsigneeData",
            checkConsigneeAgreement: "reservation/checkConsigneeAgreement",
        }),
        ...mapActions({
            createCase: "reservation/createCase",
        }),
        startEdit() {
            this.editing = true;
            this.$emit("startEdit");
        },
        save() {
            // 這裏之後要發送請求

            // 把form裏面的addressData展開
            let form = JSON.parse(JSON.stringify(this.form));
            Object.assign(form, form.addressData);
            delete form.addressData;

            for (let key of Object.keys(form)) {
                this[key] = form[key];
            }
            this.editing = false;
            this.$emit("stopEdit");
        },
        back() {
            // 為form裏面的addressData賦值
            for (let key of Object.keys(this.form.addressData)) {
                this.form.addressData[key] = this[key];
            }
            for (let key of Object.keys(this.form)) {
                if (key === "addressData") continue;
                this.form[key] = this[key];
            }

            this.editing = false;
            this.$emit("stopEdit");
        },
        validate() {
            for (let key of Object.keys(this)) {
                if (this[key] === "") {
                    this.$message({
                        type: "error",
                        message: "請完整輸入收貨地址及收貨人資料",
                    });
                    return false;
                }
            }
            if (this.checkAgreement) {
                this.setConsigneeData(this);
                this.checkConsigneeAgreement();
                this.createCase()
                    .then(() => {
                        this.next();
                    })
                    .catch((err) => {
                        this.$message({
                            type: "error",
                            message: err.message,
                        });
                    });
            } else {
                this.$message({
                    type: "error",
                    message: "請點擊確認",
                });
            }
        },
    },
    computed: {
        ...mapState({
            hasAddressData: (state) => state.reservation.hasAddressData,
            hasPatientData: (state) => state.reservation.hasPatientData,
            addressData: (state) => state.reservation.addressData,
            userData: (state) => state.reservation.userData,
            patientData: (state) => state.reservation.patientData,
            hasConsigneeData: (state) => state.reservation.hasConsigneeData,
            consigneeData: (state) => state.reservation.consigneeData,
            hasCheckedAgreement: (state) =>
                state.reservation.hasCheckedConsigneeAgreement,
        }),
    },
    mounted: function () {
        if (this.hasCheckedAgreement) this.checkAgreement = true;
        if (this.hasConsigneeData) {
            // 如果已有收货地址数据，则直接填入
            for (let key of Object.keys(this.form.addressData)) {
                let parsedKey = `consignee${key
                    .charAt(0)
                    .toUpperCase()}${key.slice(1)}`;
                this[key] = this.consigneeData[parsedKey];
                this.form.addressData[key] = this.consigneeData[parsedKey];
            }
            this.contact = this.consigneeData.consignee;
            this.form.contact = this.consigneeData.consignee;
            this.phone = this.consigneeData.consigneePhone;
            this.form.phone = this.consigneeData.consigneePhone;

        } else {
            // 如果没有，则收货地址使用之前所填地址信息，收货人信息使用用户紧急联系人信息
            for (let key of Object.keys(this.form.addressData)) {
                this[key] = this.addressData[key];
                this.form.addressData[key] = this.addressData[key];
            }
            // 用户紧急联系人信息则从病人信息（高优先度）和个人资料（低优先度）里面选
            let contactData = this.hasPatientData
                ? this.patientData
                : this.userData;
            this.form.contact = contactData.consignee;
            this.contact = contactData.consignee;
            this.form.phone = contactData.consigneePhone;
            this.phone = contactData.consigneePhone;

            // console.log(this.patientData);
            
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 16px 16px 60px 16px;
    position: relative;
    section {
        h1 {
            @include flexr-between-baseline;
            font-size: 24px;
            color: $black;
            margin: 0 0 16px 0;
            font-weight: $semi-bold;
            span {
                font-size: 16px;
            }
        }
        .content {
            height: 36px;
            padding: 0 0 16px 0;
            margin-bottom: 16px;
            border-bottom: 1px solid $black;
            font-size: 16px;
            line-height: 20px;
        }
    }
}
</style>