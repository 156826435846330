<template>
  <div class="container" :class="{ 'non-scroll': historyPatientsActive }">
    <!-- <div @click="historyPatientsActive = true">
            <span>使用其他病人資料</span>
        </div> -->
    <section>
      <el-upload
        class="dashed mb-16"
        @click="checkIfRegionIsSet"
        :disabled="form.idCardRegion !== 1 && form.idCardRegion !== 2"
        :onSuccess="idCardUploadSuccess"
        accept="image"
      >
        <img
          class="upload-result"
          v-if="form.idCardPhoto"
          :src="form.idCardPhoto"
          alt=""
        />
        <div
          v-else-if="form.idCardRegion === 1 || form.idCardRegion === 2"
          class="upload-notes"
        >
          <span>請上傳身份證件</span>
          <span v-if="form.idCardRegion === 2">居民身份證正面</span>
          <span v-else>香港身份證/護照</span>
        </div>
        <div v-else class="upload-notes">
          <span>請先選擇身份證類型</span>
        </div>
      </el-upload>
      <el-upload
        v-if="form.idCardRegion === 2"
        accept="image"
        class="dashed mb-16"
        :onSuccess="idCardFUploadSuccess"
      >
        <img
          class="upload-result"
          v-if="form.idCardPhotoF"
          :src="form.idCardPhotoF"
          alt=""
        />
        <div v-else class="upload-notes">
          <span>請上傳身份證件</span>
          <span>居民身份證反面</span>
        </div>
      </el-upload>
      <el-select
        v-model="form.idCardRegion"
        placeholder="請選擇身份證類型"
        @change="clearIdCard"
      >
        <el-option :value="1" label="香港身份證"></el-option>
        <el-option :value="2" label="中華人民共和國居民身份證"></el-option>
      </el-select>
      <el-input
        :disabled=" form.idCardPhoto==''"
        @click="checkIfRegionIsSet"
        v-model="form.idCardNumber"
        placeholder="請輸入身份證件上的號碼"
      ></el-input>
    </section>
    <section>
      <el-input
        :disabled="form.idCardPhoto==''"
        v-model="form.name"
        placeholder="輸入身份證上中文全名"
      ></el-input>
      <el-select :disabled="form.idCardPhoto==''" v-model="form.gender" placeholder="選擇性別">
        <el-option :value="1" label="男"></el-option>
        <el-option :value="2" label="女"></el-option>
      </el-select>
      <el-date-picker
      :disabled="form.idCardPhoto==''"
        v-model="form.birthday"
        placeholder="輸入出生日期"
        value-format="yyyy-MM-dd"
      ></el-date-picker>
      <el-input
        v-model="form.phone"
        placeholder="輸入電話"
        type="number"
      ></el-input>
      <div class="showIsTrueCode" :style="{ display: isShowTextCode }">
        {{ haveCodeSuceessStr }}
      </div>
      <el-input
        @blur="onInputCodeListen"
        v-model="form.promoCode"
        placeholder="輸入優惠碼"
      >
      </el-input>

      <div class="file-upload-container">
        <el-upload class="dashed" :on-success="onAttachmentUpload">
          <div class="upload-notes">
            <span>上傳附件</span>
          </div>
        </el-upload>
        <div class="upload-notes">
          <span class="title">請上傳相關文件</span>
          <span class="content">如：病歷、相、情況等</span>
          <span class="content">*每個檔案不能超過10mb</span>
        </div>
      </div>
      <div>
        <div class="file" v-for="(file, index) in form.attachment" :key="index">
          {{ file.split("/")[file.split("/").length - 1] }}
        </div>
      </div>
    </section>
    <section>
      <h1>緊急聯絡人</h1>
      <el-input
        v-model="form.emergencyContact"
        placeholder="緊急聯絡人姓名"
      ></el-input>
      <el-input
        v-model="form.emergencyContactPhone"
        placeholder="緊急聯絡人電話"
      ></el-input>
    </section>
    <section>
      <h1>收貨聯絡人</h1>
      <el-input
        v-model="form.consignee"
        placeholder="收貨聯絡人姓名"
      ></el-input>
      <el-input
        v-model="form.consigneePhone"
        placeholder="收貨聯絡人電話"
      ></el-input>
    </section>

    <section>
      <el-checkbox-group v-model="saveDataCheckList">
        <!-- <el-checkbox label="isSaveRecord"
                    >保留以上資料作下次使用</el-checkbox
                > -->
        <el-checkbox label="isSavePersonal"
          >保留以上資料作個人賬戶資料</el-checkbox
        >
      </el-checkbox-group>
      <p>請確保以上資料正確無誤。</p>
      <p>
        {{ appointmentPersonalTip }}
      </p>
    </section>
    <div class="reservation-btn-group">
      <button @click="previous">返回</button>
      <button @click="validate">下一步</button>
    </div>
    <history-patients
      :active="historyPatientsActive"
      @back="historyPatientsActive = false"
      @update="updateFormDataFromPatientData"
    ></history-patients>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import historyPatients from "./3_1_historyPatients";
import { promoCodeCheck,getIdCardInfo } from "@/api";
export default {
  components: {
    historyPatients,
  },
  data: function () {
    return {
      form: {
        // 1:香港身份證，2:大陸身份證
        idCardRegion: 1,
        // 身份證正面
        idCardPhoto: "",
        // 2021年7月8日新增需求（PSMHJZ-38）
        // 命名不是很規範，凑合過吧
        // 身份證反面
        idCardPhotoF: "",
        idCardNumber: "",
        name: "",
        // 1:男；2:女；0:未知;
        gender: "",
        birthday: "",
        phone: "",
        promoCode: "",
        emergencyContact: "",
        emergencyContactPhone: "",
        attachment: [],
        consignee: "", //收货人姓名
        consigneePhone: "", //收货人电话
      },
      historyPatientsActive: false,
      // 該array有兩個可能的item：isSavePersonal & isSaveRecord
      // 分別表示【是否保存到個人資料】&【是否保存到病人資料】
      saveDataCheckList: [],
      haveCodeSuceessStr: "",
      isShowTextCode: "none",
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.reservation.userData,
      patientData: (state) => state.reservation.patientData,
      hasPatientData: (state) => state.reservation.hasPatientData,
      selectedClinic: (state) => state.reservation.selectedClinic,
      isSaveRecord: (state) => state.reservation.isSaveRecord,
      isSavePersonal: (state) => state.reservation.isSavePersonal,
      appointmentPersonalTip: (state) => state.sysConfig.appointmentPersonalTip,
    }),
  },
  methods: {
    ...mapMutations({
      next: "reservation/next",
      previous: "reservation/previous",
      setPatientData: "reservation/setPatientData",
      confirmHasPatientData: "reservation/confirmHasPatientData",
      setSaveData: "reservation/setSaveData",
    }),
    //獲取身份證信息 進行自動填寫
    getIdCInfo(url) { 
      let data = {
        image_url: url,
        country: this.form.idCardRegion==1?'HK':'CHN',//國家：CHN中國（默認）、HK香港
        card_side:'FRONT'//大陸身份證正反面：FRONT正面（默認）、BACK反面
      }
      const that = this;
      getIdCardInfo(data).then((res) => {
        if (res.code !== 200) { 
          return
        }
        if (that.form.idCardRegion == 1) {
          var getBirList = res.data.Birthday.split('-')
          that.form.name = res.data.CnName;
          that.form.gender = (res.data.Sex + '').indexOf('女') < 0 ? 1 : 2;
          that.form.idCardNumber = res.data.IdNum;
          that.form.birthday = getBirList[2]+'-'+getBirList[1]+'-'+getBirList[0];
        } else { 
          var getBirList1 = res.data.Birth.split('/')
          that.form.name = res.data.Name;
          that.form.gender = (res.data.Sex + '').indexOf('女') < 0 ? 1 : 2;
          that.form.idCardNumber = res.data.IdNum;
          that.form.birthday = getBirList1[0]+'-'+getBirList1[1]+'-'+getBirList1[2];
        }
        
      })

    },




    checkIfRegionIsSet() {
      if (this.form.idCardRegion !== 1 && this.form.idCardRegion !== 2)
        this.$message({
          type: "error",
          message: "請先選擇身份證類型",
        });
    },
    clearIdCard() {
      this.form.idCardPhoto = "";
      this.form.idCardPhotoF = "";
      this.form.idCardNumber = "";
    },
    onAttachmentUpload(res) {
      let filePath = res.data.filePath;
      this.form.attachment.push(filePath);
    },
    // 注意區分，這個是身份證正面（只有大陸身份證區分正反）
    idCardUploadSuccess(res) {
      this.getIdCInfo(res.data.fileUrl)
      this.form.idCardPhoto = res.data.fileUrl;
    },
    // 注意區分，這個是身份證反面
    idCardFUploadSuccess(res) {
      this.form.idCardPhotoF = res.data.fileUrl;
    },
    updateFormDataFromPatientData() {
      for (let key of Object.keys(this.form)) {
        if (this.patientData[key] != undefined)
          this.form[key] = this.patientData[key];
      }
      this.historyPatientsActive = false;
    },
    async onInputCodeListen() {
      console.log("失去焦點了" + this.form.promoCode);
      this.isShowTextCode = "none";
      if (!this.form.promoCode) {
        return;
      }
      let data = {
        clinic_id: this.selectedClinic.id,
        promo_code: this.form.promoCode,
      };
      try {
        await promoCodeCheck(data).then((data) => {
          this.isShowTextCode = "";
          if (data.data.deduction != null) {
            this.haveCodeSuceessStr = "折扣金額: 港幣$" + data.data.deduction;
          }
          if (data.data.discount != null) {
            this.haveCodeSuceessStr = "優惠折扣: " + data.data.discount + "%";
          }
        });
      } catch (err) {
        this.$message({
          type: "error",
          message: err.message,
        });
        return false;
      }
    },
    async validate() {
      for (let key of Object.keys(this.form)) {
        if (["promoCode"].includes(key)) {
          if (!this.form.promoCode) continue;
          let data = {
            clinic_id: this.selectedClinic.id,
            promo_code: this.form.promoCode,
          };
          try {
            await promoCodeCheck(data);
          } catch (err) {
            this.$message({
              type: "error",
              message: err.message,
            });
            return false;
          }
        }
        if (
          (this.form[key] == undefined || this.form[key] == "") &&
          !["attachment", "promoCode"].includes(key)
        ) {
          if (key !== "idCardPhotoF" || this.form.idCardRegion !== 1) {
            this.$message({
              type: "error",
              message: "請完整輸入個人資料，如沒有優惠碼與附件請留空",
            });
            return false;
          }
        }
      }
      this.form.id = this.userData.patientId;
      this.setPatientData(this.form);
      // 以下data中的兩個變量分別表示【是否保存到個人資料】&【是否保存到病人資料】
      // 1表示是，0表示否
      let data = {
        isSaveRecord: this.saveDataCheckList.includes("isSaveRecord") ? 1 : 0,
        isSavePersonal: this.saveDataCheckList.includes("isSavePersonal")
          ? 1
          : 0,
      };
      this.setSaveData(data);
      this.confirmHasPatientData();
      this.next();
    },
  },
  created: function () {
    // 如果有patientData，则patientData优先级更高
    if (!this.hasPatientData) {
      for (let key of Object.keys(this.form)) {
        if (this.userData[key] != undefined)
          this.form[key] = this.userData[key];
      }
    } else {
      for (let key of Object.keys(this.form)) {
        if (this.patientData[key] != undefined)
          this.form[key] = this.patientData[key];
      }
    }
    // 參見data.saveDataCheckList的注釋
    if (this.isSaveRecord === 1) this.saveDataCheckList.push("isSaveRecord");
    if (this.isSavePersonal === 1)
      this.saveDataCheckList.push("isSavePersonal");
  },
};
</script>

<style lang="scss" scoped>
.showIsTrueCode {
  tab-size: 12px;
  color: red;
}
.non-scroll {
  height: calc(100vh - 48px);
  overflow: hidden;
}
.container {
  position: relative;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  & > div:first-child {
    text-align: right;
    padding: 16px 16px 0 16px;
  }
  section {
    *:last-child {
      margin-bottom: 0;
    }
    padding: 16px;
    font-size: 16px;
    border-bottom: 1px solid $yellow-200;
    h1 {
      font-size: 21px;
      color: $green-300;
      margin: 0 0 16px 0;
    }
    .upload-result {
      width: 100%;
      object-fit: cover;
    }
    .upload-notes {
      @include flexc-center-center;
      .title {
        font-size: 18px;
      }
      .content {
        font-size: 14px;
      }
    }
    .file-upload-container {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }
  section:last-of-type {
    border-bottom: none;
  }
}
</style>