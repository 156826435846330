<template>
  <div class="container">
    <section>
      <p>
        {{ appointmentNoteContent }}
      </p>
      <p>
        {{ appointmentNoteSymptom }}
      </p>
    </section>
    <section>
      <el-checkbox-group v-model="agreementCheckList">
        <el-checkbox label="1"
          >我沒有懷孕、我不是14歲以下、我沒有以上任何症狀</el-checkbox
        >
        <el-checkbox label="2">我將於診症時身處香港</el-checkbox>
        <el-checkbox label="3">我已閱讀並同意條款及細則和私穩政策</el-checkbox>
      </el-checkbox-group>
    </section>
    <section>
      <h1>{{ disclaimerTitle }}</h1>
      <p>
        {{ disclaimerContent }}
      </p>
    </section>
    <div class="reservation-btn-group">
      <button @click="back">返回</button>
      <button @click="validate">確認</button>
    </div>
  </div>
</template>

<script>
import { lastPersonals } from "@/api";
import { mapMutations, mapState } from "vuex";
import { isSafari } from "@/assets/js";
export default {
  data: function () {
    return {
      agreementCheckList: [],
    };
  },
  computed: {
    ...mapState({
      hasCheckedAgreement: (state) => state.reservation.hasCheckedAgreement,
      appointmentNoteContent: (state) => state.sysConfig.appointmentNoteContent,
      appointmentNoteSymptom: (state) => state.sysConfig.appointmentNoteSymptom,
      disclaimerTitle: (state) => state.sysConfig.disclaimerTitle,
      disclaimerContent: (state) => state.sysConfig.disclaimerContent,
      isFromApp: (state) => state.reservation.isFromApp,
    }),
  },
  methods: {
    ...mapMutations({
      next: "reservation/next",
      checkAgreement: "reservation/checkAgreement",
      setUserData: "reservation/setUserData",
    }),
    validate() {
      if (this.agreementCheckList.length != 3) {
        // 這邊的文案是我瞎編的
        this.$message({
          type: "error",
          message: "請勾選全部預約注意事項",
        });
      } else {
        //后期需要判断是否是从原生那边跳转过来的 而不是从H5那边过来的 原生那边过来的说明没有请求该接口
        // 尝试获取用户的个人资料
        lastPersonals().then((res) => {
          this.setUserData(res.data);
        });

        this.checkAgreement();
        this.next();
      }
    },
    back() {
      if (this.isFromApp) {
        let isSa = isSafari();
        try {
          window.uni.postMessage({
              data: {
                action: "exit_web",
              },
          });
          if (window.webBackToAndroid) window.webBackToAndroid.onBack();
          else if (window.webkit) {
          window.webkit.messageHandlers.getDataFromWeb.postMessage("close");
          } else if (isSa) {
          console.log("is sa ===========");
          // iOS端关闭网页方法
          window.location.href = "iMeddy://com.imeddy.client?close=1";
          } else {
          this.$router.go(-1);
          }
         } catch (e) { 
          this.$router.go(-1);
        }
      } else this.$router.go(-1);
    },
  },
  mounted: function () {
    if (this.hasCheckedAgreement) this.agreementCheckList = ["1", "2", "3"];
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  padding-bottom: 60px;
}
section {
  padding: 16px;
  font-size: 16px;
  border-bottom: 1px solid $yellow-200;
  h1 {
    font-size: 21px;
    color: $green-300;
    margin: 0;
  }
  p {
    margin-top: 0;
  }
}

section:last-of-type {
  border: none;
}
</style>