<template>
    <div class="container">
        <!-- <section>
            <h1>藥物使用</h1>
            <div class="input-container">
                <span>有</span
                ><el-input
                    v-model="form.useDrugs"
                    @focus="form.isUseDrugs = 1"
                ></el-input>
            </div>
            <el-radio
                :label="0"
                v-model="form.isUseDrugs"
                @change="clearText('drugs')"
                >沒有</el-radio
            >
        </section>
        <section>
            <h1>物質敏感</h1>
            <div class="input-container">
                <span>有</span
                ><el-input
                    v-model="form.materialSensitivity"
                    @focus="form.isMaterialSensitivity = 1"
                ></el-input>
            </div>
            <el-radio
                :label="0"
                v-model="form.isMaterialSensitivity"
                @change="clearText('material')"
                >沒有</el-radio
            >
        </section> -->
        <section v-for="question of form.questionList" :key="question.id">
            <h1>{{ question.title }}</h1>
            <div class="input-container" v-if="question.type === 2">
                <span>有</span
                ><el-input
                    v-model="question.content"
                    @focus="question.checked = false"
                ></el-input>
            </div>
            <el-radio
                v-if="question.type === 2"
                v-model="question.checked"
                :label="true"
                @change="clearText(question)"
                >沒有</el-radio
            >
            <el-input
                type="textarea"
                v-else-if="question.type === 1"
                v-model="question.content"
            ></el-input>
        </section>
        <div class="reservation-btn-group">
            <button @click="previous">返回</button>
            <button @click="validate">下一步</button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { sysDrugSensitivities, medicalRecord } from "@/api";
export default {
    data: function () {
        return {
            form: {
                // useDrugs: "",
                // isUseDrugs: 1,
                // materialSensitivity: "",
                // isMaterialSensitivity: 1,
                questionList: [],
            },
        };
    },
    computed: {
        ...mapState({
            selectedClinic: (state) => state.reservation.selectedClinic,
            hasSensitivitiesData: (state) =>
                state.reservation.hasSensitivitiesData,
            sensitivitiesData: (state) => state.reservation.sensitivitiesData,
            patientData: (state) => state.reservation.patientData,
        }),
    },
    methods: {
        ...mapMutations({
            next: "reservation/next",
            previous: "reservation/previous",
            setSensitivitiesData: "reservation/setSensitivitiesData",
        }),
        clearText(text) {
            switch (text) {
                case "material":
                    this.form.materialSensitivity = "";
                    break;
                case "drugs":
                    this.form.useDrugs = "";
                    break;
                default:
                    text.content = "";
                    break;
            }
        },
        validate() {
            let flag = true;
            // if (this.form.isUseDrugs == 1 && !this.form.useDrugs) flag = false;
            // if (
            //     this.form.isMaterialSensitivity == 1 &&
            //     !this.form.materialSensitivity
            // )
            //     flag = false;
            for (let question of this.form.questionList) {
                if (!question.checked && !question.content) {
                    flag = false;
                    break;
                }
            }
            if (flag) {
                let data = JSON.parse(JSON.stringify(this.form));
                data.drugSensitivityReply = data.questionList.map((e) => {
                    let question = {
                        drugSensitivityId: e.id,
                        reply: e.content,
                        drugSensitivityType: e.type,
                    };
                    if (e.type === 2)
                        question.isDrugSensitivity = e.checked ? 0 : 1;
                    return question;
                });
                this.setSensitivitiesData(data);
                this.next();
            } else {
                this.$message({
                    type: "error",
                    message: "請完整填寫病歷",
                });
            }
        },
    },
    mounted: function () {
        let data = {
            clinic_id: this.selectedClinic.id,
            pageSize: 999,
        };
        if (this.hasSensitivitiesData) {
            for (let key of Object.keys(this.form)) {
                if (this.sensitivitiesData[key] != undefined)
                    this.form[key] = this.sensitivitiesData[key];
            }
        }
        sysDrugSensitivities(data).then((res) => {
            res.data.dataList.forEach((e) => {
                // status为0表示该问题被禁用了
                if (e.status === 1) {
                    let content = "",
                        checked = false;
                    // 如果已有数据，则从数据里面取出问题的回复（效率低，待优化）
                    if (this.hasSensitivitiesData) {
                        this.sensitivitiesData.drugSensitivityReply.forEach(
                            (v) => {
                                if (e.id == v.drugSensitivityId) {
                                    content = v.reply;
                                    checked = v.isDrugSensitivity === 0;
                                }
                            }
                        );
                    }
                    this.form.questionList.push({
                        id: e.id,
                        title: e.title,
                        order: e.order,
                        type: e.type,
                        checked,
                        content,
                    });
                }
            });
            if (!this.hasSensitivitiesData) {
                // 在没有数据的情况下看看有没有病人的历史病历
                let data = {
                    patientId: this.patientData.id,
                    clinicId: this.selectedClinic.id,
                };
                medicalRecord(this.$underline(data)).then((answerRes) => {
                    answerRes = this.$camel(answerRes);
                    this.form.questionList.forEach((e) => {
                        // 从数据里面取出问题的回复（效率低，待优化）
                        answerRes.data.forEach((v) => {
                            if (e.id == v.drugSensitivityId) {
                                e.content = v.reply;
                                e.checked = parseInt(v.isDrugSensitivity) === 0;
                            }
                        });
                    });
                });
            }
            this.form.questionList.sort((a, b) => {
                return a.status - b.status;
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 16px 16px 60px 16px;
    section {
        margin-bottom: 16px;
        h1 {
            font-size: 18px;
        }
        .input-container {
            @include flexr-between-center;
            height: 60px;
            padding: 8px;
            border-bottom: 1px solid $black;
            span {
                margin-right: 16px;
            }
            .el-input {
                margin-bottom: 0;
            }
        }
        .el-radio ::v-deep {
            height: 60px;
            border-bottom: 1px solid $black;
            padding: 12px 8px;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            .el-radio__label {
                padding-left: 0;
                margin-top: 0;
                @include flexc-center-center;
            }
        }
    }
}
</style>