<template>
  <div class="container">
    <doctor-info
      class="doctor-info"
      :doctorData="{
        name: selectedDoctor.name,
        gender: selectedDoctor.gender,
        avatar: selectedDoctor.avatar,
        specialist: selectedDoctor.specialist,
      }"
    ></doctor-info>
    <section class="bill-info">
      <div class="item" style="margin-bottom: 0">
        <span class="name"
          >診金
          <br />
          <span v-if="isShowCenterFeeTx" class="cal_separately"
            >{{ billConsultationFeeDescription }}
            <br />
            *物流費另外計算</span
          ><span v-else class="cal_separately">
            診金一經確認將不設退款，閣下的預約詳情則會在支付預約費用並提交預約申請後確認。
            <br />
            *物流費及處方藥物費用另外計算</span
          >
        </span>

        <span class="amount">
          <span
            v-if="isShowCenterFeeTx"
            class="amount setHaveRedLine"
            style="font-size: 15px; font-weight: 400"
            >$ {{ parseFloat(reservationSuccessData.consultationFee) }}
            <div
              v-if="reservationSuccessData.promoCodeAmount != null"
              class="setRedLine"
            />
          </span>
          <div
            v-if="
              reservationSuccessData.promoCodeAmount != null &&
              isShowCenterFeeTx
            "
            style="color: red; font-size: 15px; font-weight: 400"
          >
            -${{ parseFloat(reservationSuccessData.promoCodeAmount) }}
          </div>
          <span class="setBlackFont"
            >${{
              parseFloat(
                Number(reservationSuccessData.consultationFee) -
                  Number(reservationSuccessData.promoCodeAmount)
              )
            }}</span
          >
        </span>
      </div>

      <div v-if="isShowCenterFeeTx" class="item">
        <span class="name">預約費用</span>
        <span class="amount setBlackFont"
          >$ {{ parseFloat(reservationSuccessData.bookingAmount) }}</span
        >
        <div class="detail">
          <span>*診症後將從總帳單扣除</span>
          <span>*若你錯過預約或遲到，此費用將不退還</span>
        </div>
      </div>
      <div class="total">
        <span class="name">總額</span>
        <span class="amount setBlackFont">
          $
          {{ parseFloat(reservationSuccessData.bookingAmount) }}
        </span>
      </div>
    </section>
    <payment-agreement
      @change="disabled = !$event"
      :hasChecked="hasCheckedBillAgreement"
    ></payment-agreement>
    <section class="notes-list">
      <!-- <div class="notes">
        {{ billPrompt }}
      </div> -->
      <div class="notes">
        {{ billDisclaimer }}
      </div>
    </section>
    <div class="reservation-btn-group">
      <button @click="back">返回</button>
      <button @click="validate">{{ isDonePayTx }}</button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import DoctorInfo from "../../../components/doctorInfo.vue";
import PaymentAgreement from "../../../components/paymentAgreement.vue";
export default {
  components: {
    DoctorInfo,
    PaymentAgreement,
  },
  data: function () {
    return {
      disabled: false,
      isDonePayTx: "確認",
      isShowCenterFeeTx: true,
    };
  },
  props: {
    onlyPayment: Boolean,
  },
  computed: {
    ...mapState({
      reservationSuccessData: (state) =>
        state.reservation.reservationSuccessData,
      hasCheckedBillAgreement: (state) =>
        state.reservation.hasCheckedBillAgreement,
      selectedDoctor: (state) => state.reservation.selectedDoctor,
      billDisclaimer: (state) => state.sysConfig.billDisclaimer,
      billConsultationFeeDescription: (state) =>
        state.sysConfig.billConsultationFeeDescription,
      billPrompt: (state) => state.sysConfig.billPrompt,
    }),
  },
  methods: {
    ...mapMutations({
      next: "reservation/next",
      previous: "reservation/previous",
      checkBillAgreement: "reservation/checkBillAgreement",
      setSteps: "reservation/setSteps",
    }),
    getLocationS() {
      this.isShowCenterFeeTx = location.search.indexOf("loginType") < 0;
    },
    back() {
      if (this.onlyPayment) this.$router.go(-1);
      else
      this.$router.replace({
        name: "index",
        query: {
          loginType:this.$route.query.loginType,
          memberId: this.$route.query.memberId,
          loginTypeKy: this.$route.query.loginTypeKy,
          loginTypeYy: this.$route.query.loginTypeYy,
          merchantCode: this.$route.query.merchantCode,
          merchantAccessToken:  this.$route.query.merchantAccessToken
        }
      });
    },
    validate() {
      if (this.disabled) {
        this.$message({
          type: "error",
          message: "請閲讀並同意條款及細則與隱私政策",
        });
        return false;
      }
      //進行判斷是否為0元
      console.log(this.reservationSuccessData.bookingAmount);
      if (this.reservationSuccessData.bookingAmount == "0.00") {
        // this.$router.push({
        //   name: "index",
        //   query: { loginType: this.$route.query.loginType },
        // });
        this.$router.replace({
        name: "index",
        query: {
          merchantCode: this.$route.query.merchantCode,
          merchantAccessToken: this.$route.query.merchantAccessToken,
          loginType: this.$route.query.loginType
        }
      });

      } else {
        this.checkBillAgreement();
        this.next();
      }
    },
  },
  created() {
    if (this.reservationSuccessData.bookingAmount == "0.00") {
      // this.$router.push({
      //   name: "index",
      //   query: { loginType: this.$route.query.loginType },
      // });
      this.$router.replace({
        name: "index",
        query: {
          merchantCode: this.$route.query.merchantCode,
          merchantAccessToken: this.$route.query.merchantAccessToken,
          loginType: this.$route.query.loginType
        }
      });
      
    }
    this.getLocationS();
  },
};
</script>

<style lang="scss" scoped>
.setNewTxPostion {
  position: absolute;
  margin-top: 9%;
  width: 69%;
}
.setBlackFont {
  font-size: 25px !important;
  color: #000000;
}
.setHaveRedLine {
  position: relative;
}
.setRedLine {
  top: 50%;
  right: 0px;
  display: flex;
  position: absolute;
  background-color: red;
  width: 100%;
  height: 2px;
  border-radius: 10px;
}
.cal_separately {
  width: 70%;
  font-size: 12px;
}
.container {
  padding-bottom: 60px;
  position: relative;
  .doctor-info {
    margin-top: 16px;
  }
  .bill-info {
    padding: 16px;
    background-color: $white;
    .item {
      display: grid;
      grid-template-columns: 1fr 100px;
      grid-template-rows: auto auto;
      column-gap: 16px;
      margin-bottom: 16px;
      .name {
        font-weight: $semi-bold;
        font-size: 21px;
      }
      .amount {
        text-align: right;
        font-weight: $bold;
        font-size: 21px;
      }
      .detail {
        @include flexc-center-stretch;
        font-size: 12px;
      }
    }
    .total {
      padding-top: 16px;
      border-top: 1px solid $black;
      display: grid;
      grid-template-columns: 1fr 150px;
      align-items: baseline;
      .name {
        font-size: 21px;
        font-weight: $semi-bold;
      }
      .amount {
        font-size: 28px;
        font-weight: bold;
        text-align: right;
      }
    }
  }
  .notes-list {
    padding: 16px 16px 0 16px;
    .notes {
      font-size: 12px;
      margin-bottom: 16px;
    }
  }
}
</style>