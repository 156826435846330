<template>
    <div :class="{ blue: theme === 'blue' }">
        <el-select
            @change="submitChanges"
            v-model="localAddressProvince"
            placeholder="請選擇行政區"
        >
            <el-option
                v-for="province in addressData"
                :key="province.id"
                :label="province.name"
                :value="province.name"
            >
            </el-option>
        </el-select>
        <el-select
            @change="submitChanges"
            v-model="localAddressArea"
            placeholder="請選擇區域"
            :disabled="!localAddressProvince"
        >
            <el-option
                v-for="area in areaData"
                :key="area.id"
                :label="area.name"
                :value="area.name"
            >
            </el-option>
        </el-select>
        <el-select
            @change="submitChanges"
            v-model="localAddressRegion"
            placeholder="請選擇地區"
            :disabled="!localAddressArea || !localAddressProvince"
        >
            <el-option
                v-for="region in regionData"
                :key="region.id"
                :label="region.name"
                :value="region.name"
            >
            </el-option>
        </el-select>
        <el-input
            @change="submitChanges"
            v-model="localAddress1"
            placeholder="住宅地址"
            :disabled="
                !localAddressRegion ||
                !localAddressArea ||
                !localAddressProvince
            "
        ></el-input>
    </div>
</template>

<script>
import { areaList } from "@/api";
export default {
    data: function () {
        return {
            localAddressProvince: this.value.addressProvince,
            localAddressArea: this.value.addressArea,
            localAddressRegion: this.value.addressRegion,
            localAddress1: this.value.address1,
            addressData: [],
        };
    },
    computed: {
        areaData: function () {
            return this.selectAddressData(
                this.addressData,
                this.localAddressProvince
            );
        },
        regionData: function () {
            return this.selectAddressData(this.areaData, this.localAddressArea);
        },
    },
    props: {
        theme: {
            type: String,
            required: false,
            default: "white",
            validator: function (value) {
                return ["blue", "white"].indexOf(value) != -1;
            },
        },
        value: {
            type: Object,
            required: true,
            validator: function (v) {
                // v-model绑定的对象必须要有以下这四个字段
                let keyList = [
                    "addressProvince",
                    "addressRegion",
                    "addressArea",
                    "address1",
                ];
                for (let key of Object.keys(v)) {
                    if (keyList.includes(key))
                        keyList.splice(keyList.indexOf(key), 1);
                    else return false;
                }
                if (keyList.length == 0) return true;
                else return false;
            },
        },
    },
    watch: {
        value: {
            handler: function () {
                this.localAddressArea = this.value.addressArea;
                this.localAddressRegion = this.value.addressRegion;
                this.localAddress1 = this.value.address1;
                this.localAddressProvince = this.value.addressProvince;
            },
            deep: true,
        },
    },
    methods: {
        selectAddressData(parentData, target) {
            for (let data of parentData) {
                if (data.name == target) return data.childList;
            }
            return [];
        },
        submitChanges() {
            this.$emit("input", {
                addressArea: this.localAddressArea,
                addressRegion: this.localAddressRegion,
                address1: this.localAddress1,
                addressProvince: this.localAddressProvince,
            });
        },
    },
    mounted: function () {
        areaList({ pageSize: 100 }).then((res) => {
            this.addressData = res.data.dataList;
        });
    },
};
</script>

<style lang="scss" scoped>
div.blue {
    .el-input::v-deep,
    .el-select::v-deep {
        input {
            background-color: transparent;
        }
    }
}
</style>