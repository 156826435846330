<template>
  <div class="reservation-container">
    <header
      :style="{ 'margin-top': statusBarHeight }"
      v-if="step != 11 && step != 12"
    >
      {{ title }}
      <common-questions></common-questions>
    </header>
    <attention v-if="step == 1"></attention>
    <doctor-selection v-if="step == 2"></doctor-selection>
    <patient-data v-if="step == 3"></patient-data>
    <address-data v-if="step == 4"></address-data>
    <symptom v-if="step == 5"></symptom>
    <disease-time v-if="step == 6"></disease-time>
    <other-questions v-if="step == 7"></other-questions>
    <shipment-addr-confirm
      @startEdit="step8Editing = true"
      @stopEdit="step8Editing = false"
      v-if="step == 8"
    ></shipment-addr-confirm>
    <bill :onlyPayment="onlyPayment" v-if="step == 9"></bill>
    <payment-method v-if="step == 10"></payment-method>
    <privacy-policy v-if="step == 11"></privacy-policy>
    <terms-conditions v-if="step == 12"></terms-conditions>
  </div>
</template>

<script>
import { lastPersonals, clinicList, doctorList, patientsHome } from "@/api";
import memberLoginMixin from "../../mixins/memberLogin";
import { mapActions, mapMutations, mapState } from "vuex";
import attention from "./steps/1_attention";
import doctorSelection from "./steps/2_doctorSelection";
import patientData from "./steps/3_patientData";
import addressData from "./steps/4_addressData";
import symptom from "./steps/5_symptom";
import diseaseTime from "./steps/6_diseaseTime";
import otherQuestions from "./steps/7_otherQuestions";
import shipmentAddrConfirm from "./steps/8_shipmentAddrConfirm";
import bill from "./steps/9_bill";
import paymentMethod from "./steps/10_paymentMethod";
import privacyPolicy from "../privacyPage/privacyPolicy";
import termsConditions from "../privacyPage/termsConditions";
import CommonQuestions from "../../components/commonQuestions.vue";
let that, currentUrl;
function historyListener() {
  if (that.step !== 1 && that.step !== 9) {
    that.$router.push(currentUrl);
    that.previous();
  }
}
export default {
  mixins: [memberLoginMixin],
  components: {
    attention,
    doctorSelection,
    patientData,
    addressData,
    symptom,
    diseaseTime,
    otherQuestions,
    shipmentAddrConfirm,
    bill,
    paymentMethod,
    privacyPolicy,
    termsConditions,
    CommonQuestions,
  },
  data: function () {
    return {
      step8Editing: false,
      statusBarHeight: "0px",
      stepName: [
        "預約注意事項",
        "選擇醫生",
        "個人資料",
        "住址資料",
        "症狀",
        "發病時間",
        "病歷",
        "確認收貨資料",
        "賬單",
        "付款",
      ],
      onlyPayment: false,
      memberId: "",
      clinicId: "",
      doctorId: "",
      reservationType: "video",

      merchantCode: "",
      merchantAccessToken:"",

    };
  },
  created() {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight + "px";
    }
    // this.getSystemOpen();
  },
  computed: {
    ...mapState({
      step: (state) => state.reservation.step,
    }),
    title: function () {
      if (this.step8Editing) return "編輯收貨資料";
      return this.stepName[this.step - 1];
    },
  },
  methods: {
    ...mapMutations({
      setReservationType: "reservation/setReservationType",
      clearReservationData: "reservation/clearReservationData",
      setUserData: "reservation/setUserData",
      selectClinic: "reservation/selectClinic",
      selectDoctor: "reservation/selectDoctor",
      checkFromApp: "reservation/checkFromApp",
      previous: "reservation/previous",
    }),
    ...mapActions({
      goToPaymentPage: "reservation/goToPaymentPage",
    }),
    findClinics(page, clinicID) {
      let pageSize = 20,
        hasMoreClinics = false;
      return new Promise((resolve, reject) => {
        clinicList({ page, pageSize }).then((clinicRes) => {
          hasMoreClinics = clinicRes.data.total - page * pageSize > 0;
          for (let clinic of this.$camel(clinicRes.data.dataList)) {
            if (clinic.clinicCode == clinicID) {
              resolve(clinic);
              return;
            }
          }
          if (hasMoreClinics)
            this.findClinics(page + 1, clinicID)
              .then((clinic) => resolve(clinic))
              .catch((result) => reject(result));
          else reject(false);
        });
      });
    },
    findDoctor(page, doctorID, clinicID, reservationType) {
      let pageSize = 20,
        hasMoreDoctors = false;
      let data = {
        page,
        pageSize,
        clinic_id: clinicID,
      };
      if (reservationType === "video") data.is_video_consultation = 1;
      else data.is_text_consultation = 1;
      return new Promise((resolve, reject) => {
        doctorList(data).then((doctorRes) => {
          hasMoreDoctors = doctorRes.data.total - page * pageSize > 0;
          for (let doctor of this.$camel(doctorRes.data.dataList)) {
            console.log(doctor)
            if (doctor.doctorCode == doctorID) {
              resolve(doctor);
              return;
            }
          }
          if (hasMoreDoctors)
            this.findClinics(page + 1, doctorID)
              .then((doctor) => resolve(doctor))
              .catch((result) => reject(result));
          else reject(false);
        });
      });
    },
    
    afterGetMemberId() {
      this.login(this.memberId,this.merchantCode,this.merchantAccessToken)
        .then(() => {
          this.checkIfHaveReservation();
          this.findClinics(1, this.clinicID)
            .then((clinic) => {
              this.selectClinic(clinic);
              this.findDoctor(1, this.doctorID, clinic.id, this.reservationType)
                .then((doctor) => {
                  this.selectDoctor(doctor);
                })
                .catch((err) => {
                  this.$store.state.isCanSelectDoctor = true;
                  this.$message({
                    type: "error",
                    message: "無法找到對應醫生或醫生不可用",
                  });
                  console.error(err);
                });
            })
            .catch((err) => {
              this.$store.state.isCanSelectDoctor = true;
              this.$message({
                type: "error",
                message: "無法找到對應診所或診所未開放",
              });
              console.error(err);
            });
        })
        .catch((err) => {
          this.$store.state.isCanSelectDoctor = true;
          this.$message({
            type: "error",
            message: err.message,
          });
          console.error(err);
          // this.$router.push({ name: "login" });
        });
    },
    checkIfHaveReservation() {
      let that = this;
      patientsHome().then((res) => {
        let hasReservationFlag = false;
        switch (this.reservationType) {
          case "video":
            if (res.data.visual_consultation) hasReservationFlag = true;
            break;
          case "text":
            if (res.data.text_consultation) hasReservationFlag = true;
            break;
        }
        if (hasReservationFlag) {
          that.$message({
            type: "error",
            message: "請先完成已有預約",
          });
          that.$router.push({
            name: "index",
            query:{
              loginType: this.$route.query.uniappLogin,
              loginTypeYy:  this.$route.query.uniappLoginYy,
              merchantCode: that.merchantCode,
              merchantAccessToken: that.merchantAccessToken
          }
          });
          // this.$router.go(-1);
        }
      });
    },
      getSystemOpen() {
          let p = navigator.platform;
          let u = navigator.userAgent;
          let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
          let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          if (
            p == "Win32" ||
            p == "Win64" ||
            p == "MacPPC" ||
            p == "MacIntel" ||
            p == "X11" ||
            p == "Linux i686"
          ) {
            //PC调试环境
            console.log("PC调试环境111")
            // window.close()
            // window.open("https://imeddy.com/")//直接打開該鏈接
            // window.location = "https://imeddy.com/"
          } else {
            if (is_android) {
              //Android终端
              console.log("Android终端")
              // this.$message({
              // 	type: "success",
              // 	message: "Android终端",
              // });
            } else if (is_ios) {
              console.log("IOS终端")
              // this.$message({
              // 	type: "success",
              // 	message: "IOS终端",
              // });
            
            }
          }
      },
  },
  mounted: function () {
    this.getSystemOpen();
    that = this;
    currentUrl = "/" + document.URL.split("/").slice(3).join("/");
    window.removeEventListener("popstate", historyListener);
    window.addEventListener("popstate", historyListener);

    window.setMemberId = this.setMemberId;
    this.reservationType = this.$route.query.type;
    if (!this.reservationType) this.reservationType = "video";
    this.setReservationType(this.reservationType);
    // 如果是从APP跳转过来的，则从url中取参数并自动填入医生和诊所
    this.clinicID = this.$route.query.clinicCode;
    this.doctorID = this.$route.query.doctorCode;

    if (this.$route.query.clinicCode != null && this.$route.query.doctorCode!=null) { 
      this.$store.state.isCanSelectDoctor = false;
    }
    this.$store.state.uniappLogin = this.$route.query.loginType;
    this.merchantCode = this.$route.query.merchantCode;
    this.merchantAccessToken = this.$route.query.merchantAccessToken;
    if (this.clinicID && this.doctorID) {
      this.checkFromApp();
      // 安卓获取memberId
      if (window.webBackToAndroid) {
        this.memberId = window.webBackToAndroid.getMemberId();
        this.afterGetMemberId();
      }
      // iOS获取memberId
      else if (window.webkit) {
        try {
          window.webkit.messageHandlers.getDataFromWeb.postMessage("memberId");
        } catch (err) {
          if (this.$route.query.memberId == null) {
            this.$message({
              type: "error",
              message: "登陸失敗，請重試",
            });
            // this.$router.push({ name: "login" });
          } else {
            this.memberId = this.$route.query.memberId;
            this.isOpenHistory = this.$route.query.isOpenHistory;
            this.afterGetMemberId();
          }
        }
      } else {
        if (this.$route.query.memberId == null) {
          this.$message({
            type: "error",
            message: "登陸失敗，請重試",
          });
          // this.$router.push({ name: "login" });
        } else {
          this.memberId = this.$route.query.memberId;
          this.isOpenHistory = this.$route.query.isOpenHistory;
          this.afterGetMemberId();
        }
      }
      // lastPersonals().then((res) => {
      // 	this.setUserData(res.data)
      // })

      // fixme: 测试专用
      // // memberId = "0eb3c233-59f1-36ec-bcb9-2f7f9a08308b";
    } else {
      // 尝试获取用户的个人资料
      lastPersonals().then((res) => {
        this.setUserData(res.data);
      });
      let path = this.$route.path;
      // 如果不是app跳轉，先檢查是否是來支付預約費用的
      if (path.match(/\/reservation\/payment\/.*/)) {
        this.onlyPayment = true;
        let caseId = this.$route.params.id;
        this.goToPaymentPage(caseId);
      } else {
        // 如果不是支付預約費用的，則檢查是否已有未完成預約
        this.checkIfHaveReservation();
      }
    }
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.step === 1 || this.step === 9) this.clearReservationData();
    next();
  },
};
</script>

<style lang="scss" scoped>
.reservation-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100vh;
  padding: var(--safe-top) 0 var(--safe-bottom);
  div {
    flex-grow: 1;
  }
}
header {
  font-size: 24px;
  color: $green-300;
  font-weight: $semi-bold;
  padding: 16px 16px 0 16px;
  @include flexr-between-center;
}
</style>