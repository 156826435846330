<template>
  <div>
    <section class="list-container" :class="{ active }">
      <header @click="back" :style="{ 'margin-top': statusBarHeight }">
        <i class="icon-leftarrow"></i>
        {{ title }}
      </header>
      <div
        class="list-wrapper"
        @scroll="infiniteScroll($event, 'clinic')"
        v-if="type === 'clinic'"
      >
        <section
          v-for="clinic in clinicList"
          :key="clinic.id"
          class="clinic-container"
          @click="setClinic(clinic)"
        >
          <div class="clinic_div">
            <i></i>
            <img :src="clinic.logo" class="logo" />
          </div>
          <div class="content">
            <span style="font-size: 4vw" >{{ clinic.name }}</span>
            <span style="font-size: 3.5vw; margin-top: 1vh"
              >診所地址：{{ clinic.address }}</span
            >
            <span style="font-size: 3.5vw; margin-top: 0.5vh"
              >診所電話：{{ clinic.phone }}</span
            >
          </div>
        </section>
      </div>
      <div
        class="list-wrapper"
        @scroll="infiniteScroll($event, 'doctor')"
        v-if="type === 'doctor'"
      >
        <!-- 整体的一个item -->
        <div class="newui_item" v-for="doctor in doctorList" :key="doctor.id">
          <!-- 内容文字 -->
          <div class="newui_contx" @click="setDoctor(doctor)">
            <div class="contx_left_tx">
              <span class="set_center_tx_name" :style="{color:doctor.isconsultation==1?'#000000':'#cccccc'} ">{{ doctor.name }}</span>
              <span class="set_center_tx" style="font-size: 3.5vw" :style="{color:doctor.isconsultation==1?'#000000':'#cccccc'} ">{{
                doctor.specialist
              }}</span>
              <span class="set_center_tx" style="font-size: 3.5vw" :style="{color:doctor.isconsultation==1?'#000000':'#cccccc'} ">{{
                doctor.clinicName
              }}</span>
            </div>
            <!-- 诊金额 -->
            <div class="newui_pay">
              <span v-if=" doctor.fee!='$ 0.00'" style="font-size: 3.5vw" :style="{color:doctor.isconsultation==1?'#000000':'#cccccc'} ">診金</span>
              <div
                v-if="pager_type == 'text'"
                style="color: #fe7d09; font-size: 16px" 
                :style="{color:doctor.isconsultation==1?'#fe7d09':'#cccccc'} ">
                {{ doctor.textfee }}
              </div>
              <div
                v-else
                style="color: #fe7d09; font-size: 16px; margin-top: 6px" 
                :style="{color:doctor.isconsultation==1?'#fe7d09':'#cccccc'} "
              >
                <div v-if=" doctor.fee!='$ 0.00'">{{ doctor.fee }}</div>
              </div>
            </div>
          </div>
          <!-- 医生头像 和下方的对应文字-->
          <div class="doctor_img_tx">
            <img
            object-fit="cover"
              class="doctor_img"
              @click="setDoctor(doctor)"
              :src="doctor.avatar"
            />
            <h1
              class="h1_introduction"
              @click="seeIntroduction(doctor.introduction)"
            >
              醫生簡介
              <img
                style="width: 20px; height: 20px"
                src="../../../assets/img/down_arrow.png"
              />
              <!-- <i class="el-icon-arrow-up" /> -->
            </h1>
          </div>
        </div>
      </div>
    </section>
    <!-- 醫生簡介提示  close-on-click-modal 禁止點擊其他地方-->
    <el-dialog
      title="簡介"
      :visible.sync="dialogVisible"
      width="80%"
      style="margin-top: 30%"
      :close-on-click-modal="false"
    >
      <div v-for="(getTx, index) in getIndroductionArr" :key="index">
        <span>{{ getTx }}<br /></span>
      </div>
      <el-button class="setbtn-check" type="primary" @click="closeDialog"
        >確定</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { doctorList, clinicList } from "@/api";
export default {
  data: function () {
    return {
      doctorList: [],
      clinicList: [],
      hasMoreClinic: true,
      hasMoreDoctor: true,
      currentClinicPage: 0,
      currentDoctorPage: 0,
      logo_width: "14vw",
      logo_height: "8vh",
      logo_margin_top: "-1.5vh",
      logo_margin_left: "0.5vw",
      doctors_content_margin_top: "3vh",
      doctors_content_margin_left: "0vw",
      doctor_div_width: "",
      doctor_container_height: "",
      pager_type: "text",
      statusBarHeight: "0px",
      dialogVisible: false,
      getIndroductionArr: [],
    };
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ["doctor", "clinic"].indexOf(value) != -1;
      },
    },
  },
  computed: {
    ...mapState({
      selectedClinic: (state) => state.reservation.selectedClinic,
      reservationType: (state) => state.reservation.reservationType,
    }),
    title: function () {
      return this.type === "clinic" ? "選擇診所" : "選擇醫生";
    },
    genderInText: function () {
      return (gender) => {
        return gender == 1 ? "男" : "女";
      };
    },
  },
  created: function () {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight - 30 + "px";
    }
    this.clientWidths();
    this.pager_type = this.$route.query.type; //分辨跳轉過來的類型 但是在瀏覽器會報錯 但是不影響
  },
  mounted() {
    const that = this;
    // 实时监听手机端宽度是否修改 修改时触发
    window.onresize = () => {
      // window.screenWidth = document.body.clientWidth;
      // that.screenWidth = window.screenWidth;
      this.clientWidths();
    };

    this.getClinicList();
    if (this.selectedClinic.id && this.doctorList.length === 0)
      this.getDoctorList(this.selectedClinic.id);
  },
  methods: {
    //设置弹框 内容分割换行
    seeIntroduction(showTx) {
      if (showTx.length < 4) {
        showTx = "醫生簡介正在審核登記中...";
      }
      this.getIndroductionArr = showTx.split(",");
      this.dialogVisible = true;
    },
    //关闭按钮弹框
    closeDialog() {
      this.dialogVisible = false;
    },
    clientWidths() {
      let clientWidth = document.body.clientWidth;
      let clientHeight = document.body.clientHeight;
      if (clientWidth === 360) {
        this.logo_width = "13vw";
        this.logo_height = "7vh";
        this.logo_margin_top = "-1.2vh";
        this.logo_margin_left = "0.9vw";
        this.doctors_content_margin_top = "3vh";
        this.doctor_div_width = "13.9vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "20vh";
      } else if (clientWidth === 411 && clientHeight === 731) {
        this.logo_width = "14vw";
        this.logo_height = "8vh";
        this.logo_margin_top = "-1vh";
        this.logo_margin_left = "1.2vw";
        this.doctor_div_width = "15.2vw";
        this.doctors_content_margin_top = "3vh";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "20vh";
      } else if (clientWidth === 411 && clientHeight === 823) {
        this.logo_width = "14vw";
        this.logo_height = "7vh";
        this.logo_margin_top = "-0.8vh";
        this.logo_margin_left = "1vw";
        this.doctors_content_margin_top = "3vh";
        this.doctor_div_width = "15vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "18vh";
      } else if (clientWidth === 320) {
        this.logo_width = "12vw";
        this.logo_height = "7vh";
        this.doctors_content_margin_top = "3vh";
        this.logo_margin_top = "-1.5vh";
        this.logo_margin_left = "0.8vw";
        this.doctor_div_width = "12.8vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "21vh";
      } else if (clientWidth === 375 && clientHeight === 667) {
        this.logo_width = "13vw";
        this.logo_height = "7vh";
        this.logo_margin_top = "-1.2vh";
        this.doctors_content_margin_top = "3vh";
        this.logo_margin_left = "1.2vw";
        this.doctor_div_width = "14.2vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "20vh";
      } else if (clientWidth === 414) {
        this.logo_width = "13vw";
        this.logo_height = "7vh";
        this.doctors_content_margin_top = "3vh";
        this.logo_margin_top = "-1vh";
        this.logo_margin_left = "1.4vw";
        this.doctor_div_width = "14.4vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "20vh";
      } else if (clientWidth === 375 && clientHeight >= 800) {
        this.logo_width = "13vw";
        this.logo_height = "6vh";
        this.logo_margin_top = "-1vh";
        this.logo_margin_left = "1.2vw";
        this.doctors_content_margin_top = "3vh";
        this.doctor_div_width = "14.2vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "18vh";
      } else if (clientWidth === 768) {
        this.logo_width = "14vw";
        this.logo_height = "9vh";
        this.logo_margin_top = "-0.2vh";
        this.doctors_content_margin_top = "3vh";
        this.logo_margin_left = "3.2vw";
        this.doctors_content_margin_left = "10vw";
        this.doctor_div_width = "17.2vw";
        this.doctor_container_height = "24vh";
      } else if (clientWidth === 1024) {
        this.logo_width = "13vw";
        this.doctors_content_margin_top = "3vh";
        this.logo_height = "8vh";
        this.logo_margin_top = "0vh";
        this.logo_margin_left = "4.5vw";
        this.doctor_div_width = "17vw";
        this.doctors_content_margin_left = "10vw";
        this.doctor_container_height = "21vh";
      } else if (clientWidth === 540) {
        this.logo_width = "13vw";
        this.logo_height = "8vh";
        this.logo_margin_top = "-1vh";
        this.doctors_content_margin_top = "3vh";
        this.logo_margin_left = "3vw";
        this.doctor_div_width = "16vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "20vh";
      } else if (clientWidth === 280) {
        this.logo_width = "12vw";
        this.logo_height = "5vh";
        this.logo_margin_top = "-1.6vh";
        this.doctors_content_margin_top = "3vh";
        this.logo_margin_left = "-0.1vw";
        this.doctor_div_width = "11.9vw";
        this.doctors_content_margin_left = "0vw";
        this.doctor_container_height = "15vh";
      } else if (clientWidth === 428) {
        this.logo_width = "12vw";
        this.logo_height = "6vh";
        this.logo_margin_top = "-0.5vh";
        this.logo_margin_left = "1.5vw";
        this.doctors_content_margin_top = "3vh";
        this.doctors_content_margin_left = "0vw";
        this.doctor_div_width = "15vw";
        this.doctor_container_height = "18vh";
      } else {
        this.logo_width = "14vw";
        this.logo_height = "8vh";
        this.logo_margin_top = "-1.2vh";
        this.logo_margin_left = "1vw";
        this.doctors_content_margin_top = "3vh";
        this.doctors_content_margin_left = "0vw";
        this.doctor_div_width = "15vw";
        this.doctor_container_height = "18vh";
      }
    },
    ...mapMutations({
      selectClinic: "reservation/selectClinic",
      selectDoctor: "reservation/selectDoctor",
    }),
    infiniteScroll(event, type) {
      let target = event.target;
      let diff = target.scrollHeight - target.scrollTop - target.clientHeight;
      if (diff >= 1) return;
      switch (type) {
        case "doctor":
          this.hasMoreDoctor &&
            this.getDoctorList(this.selectedClinic.id, this.currentDoctorPage);
          break;
        case "clinic":
          this.hasMoreClinic && this.getClinicList(this.currentClinicPage);
          break;
      }
    },
    back() {
      this.$emit("back");
    },
    setClinic(clinicData) {
      console.log(clinicData);
      this.selectClinic(clinicData);
      this.getDoctorList(clinicData.id);
      this.back();
    },
    setDoctor(doctorData) {
      console.log(doctorData.id + "========" + doctorData.clinicId);
      if(doctorData.isconsultation!=1){
        this.$message({
            type: "error",
            message: "該醫生未設置排班時間",
        });
        return;
      }
      this.selectDoctor(doctorData);
      this.back();
    },
    getDoctorList(clinicId, page = 1) {
      let data = {
        // clinic_id: clinicId,
        merchant_code: "iMeddy",
        page,
      };
      if (this.$store.state.uniappLogin) {
        data = {
          merchant_code: "HK_GYT",
          page,
        };
      }
      if (this.$store.state.uniappLoginKy) {
        data = {
          merchant_code: "HK_KY",
          page,
        };
      }
      if (this.$store.state.uniappLoginYy) {
        data = {
          merchant_code: "HK_IMDOCTOR",
          page,
        };
      }
      this.currentDoctorPage = page + 1;
      // if (this.reservationType === "video") data.is_video_consultation = 1;
      // else data.is_text_consultation = 1;
      doctorList(data).then((res) => {
        if (page === 1) this.doctorList = [];
        res.data.dataList.forEach((e) => {
          this.doctorList.push({
            id: e.id,
            name: e.name,
            specialist: e.specialist,
            star: e.star,
            gender: e.gender,
            avatar: e.avatar,
            availableDateList: e.availableDateList,
            introduction: e.introduction,
            fee: "$ " + parseFloat((e.reservation_price)!=null?e.reservation_price:0).toFixed(2),
            textfee: "$ " + parseFloat(e.text_consultation_fee).toFixed(2),
            clinicId: e.clinic_id,
            clinicName: e.clinic_name,
            isconsultation:e.is_consultation
          });
        });
        if (parseInt(res.data.total) > this.doctorList.length)
          this.hasMoreDoctor = true;
        else this.hasMoreDoctor = false;
      });
    },
    getClinicList(page = 1) {
      this.currentClinicPage = page + 1;
      clinicList({ page }).then((res) => {
        if (page === 1) this.clinicList = [];
        res.data.dataList.forEach((e) => {
          this.clinicList.push({
            id: e.id,
            name: e.name,
            logo: e.logo,
            phone: e.phone,
            address: e.address,
          });
        });
        if (parseInt(res.data.total) > this.clinicList.length) {
          this.hasMoreClinic = true;
        } else {
          this.hasMoreClinic = false;
        }
        this.getDoctorList(this.clinicList[0].id);
      });
    },
  },
};
</script>

<style lang="scss" >
</style>
<style lang="scss" scoped>
.h1_introduction {
  margin-top: 10%;
  font-size: 3vw;
  text-align: center;
  color: #8b8c90;
}
.setbtn-check {
  margin-left: 70%;
  margin-top: 20px;
  width: 80px;
  height: 36px;
}
.newui_introduction {
  padding: 5%;
  left: 5%;
  width: 90%;
  height: 15%;
  position: relative;
  bottom: 0%;
}
.set_center_tx_name {
  font-size: 18px;
  color: #2b2c30;
  margin-bottom: 6px;
}
.set_center_tx {
  margin-top: 3px;
  font-size: 16px;
}
.newui_pay {
  // width: 18%;
  height: 100%;
  // border: black 1px solid;
  position: absolute;
  right: 4%;
  flex-direction: column;
  display: flex;
  font-weight: 600;
  color: #8b8c90;
  align-items: center;
}
.contx_left_tx {
  width: 53%;
  height: 100%;
  // border: black 1px solid;
  position: absolute;
  left: 25%;
  flex-direction: column;
  display: flex;
}
.doctor_img_tx {
  display: flex;
  width: 16%;
  height: 100%;
  // border: green 1px solid;
  position: absolute;
  top: 6.2%;
  left: 5.2%;
  flex-direction: column;
  display: flex;
  font-size: 10px;
  align-items: center; //水平居中
}
.doctor_img {
  display: flex;
  width: 100%;
  height: 55px;
  border-radius: 60%;
}
.newui_contx {
  display: flex;
  width: 100%;
  height: 75%;
  // border: blue 1px solid;
  position: absolute;
  bottom: 0%;
}
.newui_item {
  width: 100%;
  height: 130px;
  position: relative;
  background-size: 100% 100%;
  background-image: url("../../../assets/img/doctors.png");
}

.doctors_content {
  display: flex;
  flex-direction: column;
  word-break: break-all;
  overflow: hidden;
}
.imgs {
  width: 20px;
  height: 20px;
}
.clinic_div {
  i {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }
  img {
    vertical-align: middle;
  }
  p {
    vertical-align: middle;
  }
}
.doctor_div {
  img {
    vertical-align: middle;
  }
  p {
    vertical-align: middle;
  }
}
.list-container {
  padding: 16px 16px 0 16px;
  // background-image: url("../../../assets/img/list-container-bg.png");
  background-color: #fff;
  background-size: cover;
  background-position: center;
  z-index: 600;
  @include open-from-right;
  // 不許有動畫。_。
  transition: none;
  header {
    color: $green-300;
    font-size: 24px;
    height: 40px;
    line-height: 40px;
    span {
      font-weight: $semi-bold;
    }
  }
}

.active {
  transform: scaleX(1);
}

.list-wrapper {
  margin-top: 16px;
  height: calc(100vh - 72px);
  // display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  .doctor-container {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 70px auto;
    padding: 16px;
    gap: 16px;
    background-size: 100% 100%;
    background-image: url("../../../assets/img/doctors.png");
    .content {
      display: flex;
      flex-direction: column;
      color: $blue-300;
      h1 {
        font-size: 18px;
        font-weight: $semi-bold;
        margin: 0;
        display: flex;
        align-items: baseline;
        span {
          margin-left: 16px;
          font-size: 12px;
          font-weight: $regular;
        }
      }
    }
    .logo {
      // width: 70px;
      // height: 70px;
      // width: 14vw;
      // height: 8vh;
      border-radius: 50%;
      object-fit: cover;
      // margin-top: -1.5vh;
      // margin-left: 0.5vw;
    }
  }
  .clinic-container {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 70px auto;
    padding: 16px;
    gap: 16px;
    background-size: 100% 100%;
    background-image: url("../../../assets/img/clinic.png");
    background-color: rgba($color: white, $alpha: 0.7);
    .content {
      display: flex;
      flex-direction: column;
      color: $blue-300;
      h1 {
        font-size: 18px;
        font-weight: $semi-bold;
        margin: 0;
        display: flex;
        align-items: baseline;
        span {
          margin-left: 16px;
          font-size: 12px;
          font-weight: $regular;
        }
      }
    }
    .logo {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .doctor-container {
    // grid-template-rows: auto auto;
    // grid-template-areas: "content logo" "intro intro";
    .content {
      grid-area: content;
    }
    .logo {
      grid-area: logo;
    }
    .introduction {
      grid-area: intro;
    }
  }
}
</style>