var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('doctor-info',{staticClass:"doctor-info",attrs:{"doctorData":{
      name: _vm.selectedDoctor.name,
      gender: _vm.selectedDoctor.gender,
      avatar: _vm.selectedDoctor.avatar,
      specialist: _vm.selectedDoctor.specialist,
    }}}),_c('section',{staticClass:"bill-info"},[_c('div',{staticClass:"item",staticStyle:{"margin-bottom":"0"}},[_c('span',{staticClass:"name"},[_vm._v("診金 "),_c('br'),(_vm.isShowCenterFeeTx)?_c('span',{staticClass:"cal_separately"},[_vm._v(_vm._s(_vm.billConsultationFeeDescription)+" "),_c('br'),_vm._v(" *物流費另外計算")]):_c('span',{staticClass:"cal_separately"},[_vm._v(" 診金一經確認將不設退款，閣下的預約詳情則會在支付預約費用並提交預約申請後確認。 "),_c('br'),_vm._v(" *物流費及處方藥物費用另外計算")])]),_c('span',{staticClass:"amount"},[(_vm.isShowCenterFeeTx)?_c('span',{staticClass:"amount setHaveRedLine",staticStyle:{"font-size":"15px","font-weight":"400"}},[_vm._v("$ "+_vm._s(parseFloat(_vm.reservationSuccessData.consultationFee))+" "),(_vm.reservationSuccessData.promoCodeAmount != null)?_c('div',{staticClass:"setRedLine"}):_vm._e()]):_vm._e(),(
            _vm.reservationSuccessData.promoCodeAmount != null &&
            _vm.isShowCenterFeeTx
          )?_c('div',{staticStyle:{"color":"red","font-size":"15px","font-weight":"400"}},[_vm._v(" -$"+_vm._s(parseFloat(_vm.reservationSuccessData.promoCodeAmount))+" ")]):_vm._e(),_c('span',{staticClass:"setBlackFont"},[_vm._v("$"+_vm._s(parseFloat( Number(_vm.reservationSuccessData.consultationFee) - Number(_vm.reservationSuccessData.promoCodeAmount) )))])])]),(_vm.isShowCenterFeeTx)?_c('div',{staticClass:"item"},[_c('span',{staticClass:"name"},[_vm._v("預約費用")]),_c('span',{staticClass:"amount setBlackFont"},[_vm._v("$ "+_vm._s(parseFloat(_vm.reservationSuccessData.bookingAmount)))]),_vm._m(0)]):_vm._e(),_c('div',{staticClass:"total"},[_c('span',{staticClass:"name"},[_vm._v("總額")]),_c('span',{staticClass:"amount setBlackFont"},[_vm._v(" $ "+_vm._s(parseFloat(_vm.reservationSuccessData.bookingAmount))+" ")])])]),_c('payment-agreement',{attrs:{"hasChecked":_vm.hasCheckedBillAgreement},on:{"change":function($event){_vm.disabled = !$event}}}),_c('section',{staticClass:"notes-list"},[_c('div',{staticClass:"notes"},[_vm._v(" "+_vm._s(_vm.billDisclaimer)+" ")])]),_c('div',{staticClass:"reservation-btn-group"},[_c('button',{on:{"click":_vm.back}},[_vm._v("返回")]),_c('button',{on:{"click":_vm.validate}},[_vm._v(_vm._s(_vm.isDonePayTx))])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail"},[_c('span',[_vm._v("*診症後將從總帳單扣除")]),_c('span',[_vm._v("*若你錯過預約或遲到，此費用將不退還")])])}]

export { render, staticRenderFns }