import { mapMutations } from "vuex";
import { memberLogin, patientsCallBack } from "@/api";
const mixin = {
    methods: {
        ...mapMutations({
            afterLogin: "user/afterLogin",
        }),
        //id 用戶  商戶編碼  商戶token
        login(memberId, merchantCode, merchantAccessToken) {
            return new Promise((resolve, reject) => {
                let SALT = merchantAccessToken;
                let time = new Date().getTime();
                let rawSign = `company_code=${merchantCode}&member_id=${memberId}&time=${time}${SALT}`;
                let companyCode = merchantCode;
                if (merchantAccessToken == undefined || merchantAccessToken == null || merchantAccessToken.length < 3) {
                    SALT = "IYYber0mKM5merTrfq_YICl1258DmGKw";
                    time = new Date().getTime();
                    rawSign = `company_code=iMeddy&member_id=${memberId}&time=${time}${SALT}`;
                    companyCode = 'iMeddy';

                    if (this.$route.query.loginType) {
                        SALT = "LAFbfMQr1gusbkdeP00n4noJFZoVPRrs";
                        companyCode = 'HK_GYT'
                        rawSign = `company_code=HK_GYT&member_id=${memberId}&time=${time}${SALT}`;
                    }
                    if (this.$route.query.loginTypeKy) {
                        SALT = this.$route.query.getAccountToken;
                        companyCode = 'HK_KY';
                        rawSign = `company_code=HK_KY&member_id=${memberId}&time=${time}${SALT}`;
                    }
                    if (this.$route.query.loginTypeYy) {
                        SALT = this.$route.query.getAccountToken;
                        companyCode = 'HK_IMDOCTOR';
                        rawSign = `company_code=HK_IMDOCTOR&member_id=${memberId}&time=${time}${SALT}`;
                    }
                }

                let sha1 = require("sha1");
                let sign = sha1(rawSign).toUpperCase();
                let data = {
                    company_code: companyCode,
                    member_id: memberId,
                    time: time,
                    sign,
                };
                console.log(JSON.stringify(data))
                memberLogin(data)
                    .then((loginRes) => {
                        let userInfo = localStorage.getItem("userInfo");
                        if (!userInfo || loginRes.data.userId != JSON.parse(userInfo).userId) {
                            this.$message({
                                type: "success",
                                message: "登錄成功！",
                            });
                        }
                        this.afterLogin(loginRes.data);
                        resolve(loginRes);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
export default mixin;
