<template>
  <div class="container">
    <img src="@/assets/img/doctor.png" alt="" />
    <section>
      <div
        :style="{ display: getIsDocComeIn == true ? 'none' : 'none' }"
        class="selection"
        @click="openClinicList"
      >
        <!-- <span v-if="!selectedClinic.name" class="placeholder">
          選擇診所 {{ isHaveDoc }}</span
        > -->
        <span v-if="!selectedClinic.name" class="placeholder"> 選擇診所</span>
        <span v-else> {{ selectedClinic.name }}</span>
        <i class="icon-rightarrow"></i>
      </div>
      <div class="selection" @click="openDoctorList">
        <span v-if="!selectedDoctor.name" class="placeholder"> 選擇醫生 </span>
        <span v-else>
          {{ selectedDoctor.name }}
          <span
            v-if="selectedDoctor.showFee.length > 3"
            style="margin-left: 20px"
            >問診費用:{{ selectedDoctor.showFee }}</span
          >
        </span>
        <i class="icon-rightarrow"></i>
      </div>
      <el-select
        v-if="reservationType === 'video'"
        placeholder="選擇日期"
        v-model="appointmentDate"
      >
        <el-option
          v-for="(date, index) in dateList"
          :label="date.availableDate"
          :value="date.availableDate"
          :key="index"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="reservationType === 'video'"
        placeholder="選擇時間"
        v-model="appointmentTime"
      >
        <el-option
          v-for="(time, index) in timeList"
          :key="index"
          :label="time"
          :value="time"
        >
        </el-option>
      </el-select>
    </section>
    <div class="reservation-btn-group">
      <button @click="previous">返回</button>
      <button @click="validate">下一步</button>
    </div>

    <!-- 诊所/医生选择页面 -->
    <list-container
      :active="listActive"
      :type="listType"
      @back="listActive = false"
    ></list-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import listContainer from "./2_1_listContainer";
import { availableTimeQuery } from "@/api";
export default {
  components: {
    listContainer,
  },
  data: function () {
    return {
      listActive: false,
      listType: "clinic",
      appointmentDate: "",
      appointmentTime: "",

    };
  },
  computed: {
    ...mapState({
      reservationType: (state) => state.reservation.reservationType,
      selectedClinic: (state) => state.reservation.selectedClinic,
      selectedDoctor: (state) => state.reservation.selectedDoctor,
      appointmentData: (state) => state.reservation.appointmentData,
      hasAppointmentData: (state) => state.reservation.hasAppointmentData,
      getIsDocComeIn: (state) => state.reservation.getIsDocComeIn,
    }),
    dateList: function () {
      if (this.selectedDoctor.availableDateList["length"] == 0) {
        return [{ availableDate: "無數據" }];
      } else {
        return this.selectedDoctor.availableDateList;
      }
    },
    timeList: function () {
      for (let date of this.dateList) {
        if (date.availableDate === this.appointmentDate)
          return date.availableTime;
      }
      return ["無數據"];
    },
  },
  methods: {
    ...mapMutations({
      next: "reservation/next",
      previous: "reservation/previous",
      setAppointmentData: "reservation/setAppointmentData",
    }),
    openClinicList() {
      this.listActive = true;
      this.listType = "clinic";
    },
    openDoctorList() {
      // if (!this.selectedClinic.id) {
      //   this.$message({
      //     type: "error",
      //     message: "請先選擇診所",
      //   });
      //   return;
      // }
      //如果是從外面進入存在醫生選擇了 而且存在這個醫生 就不給再點擊選擇其他醫生了
      if (!this.$store.state.isCanSelectDoctor) { 
        this.$message({
          type: "info",
          message: "當前已選擇確認醫生，不可再選",
        });
        return
      }
      this.listActive = true;
      this.listType = "doctor";
    },
    validate() {
      if (this.reservationType === "text") {
        if (this.selectedDoctor.id && this.selectedClinic.id) this.next();
        else
          this.$message({
            type: "error",
            message: "請完成診所及醫生的選擇",
          });
      } else {
        if (
          // this.selectedClinic.id &&
          this.selectedDoctor.id &&
          this.appointmentDate &&
          this.appointmentTime
        ) {
          this.setAppointmentData(this);
          this.next();
        } else {
          this.$message({
            type: "error",
            message: "請完成診所，醫生以及預約時間的選擇",
          });
        }
        // if (
        //   this.selectedClinic.id &&
        //   this.selectedDoctor.id &&
        //   this.appointmentDate &&
        //   this.appointmentTime
        // ) {
        //   availableTimeQuery({
        //     doctor_id: this.selectedDoctor.id,
        //     appointment_date: this.appointmentDate,
        //     appointment_time: this.appointmentTime,
        //   })
        //     .then((res) => {
        //       this.setAppointmentData(this);
        //       this.next();
        //       console.log(res);
        //     })
        //     .catch((err) => {
        //       this.$message({
        //         type: "error",
        //         message: err.message,
        //       });
        //     });
        // } else {
        //   this.$message({
        //     type: "error",
        //     message: "請完成診所，醫生以及預約時間的選擇",
        //   });
        // }
      }
    },
  },
  mounted: function () {
    if (this.hasAppointmentData) {
      this.appointmentDate = this.appointmentData.appointmentDate;
      this.appointmentTime = this.appointmentData.appointmentTime;
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  position: relative;
  padding: 16px 16px 60px 16px;
  img {
    width: 100%;
    margin-bottom: 16px;
  }
}
section {
  .selection {
    border: 1px solid $black;
    padding: 0 15px;
    font-weight: $regular;
    margin-bottom: 16px;
    font-size: 16px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black;
    .placeholder {
      color: $gray;
    }
    i {
      color: #c0c4cc;
      font-size: 14px;
      display: inline-block;
      width: 25px;
      text-align: center;
    }
  }
}
</style>